import Button from "./UI/Button";
import { IoChevronDownOutline } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";
// import { statusData, statusTypes } from "../helpers/order";
import { useCallback } from "react";
import { editOrderStatus } from "../services/order";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { convertColor } from "../helpers/convertColor";

const Status = (item) => {
  const { data } = useSelector((state) => state.status);
  const statuses = item?.data?.statuses ?? [];
  const statusActive =
    statuses[0]?.status?.id && data.find((e) => e.id === statuses[0].status.id);

  const onSubmit = useCallback((data) => {
    if (!item.data) {
      NotificationManager.error("Нет данные заказа");
    }
    if (!data) {
      NotificationManager.error("Нет статуса заказа");
    }
    if (item.onChange) {
      item.onChange(data);
    }

    item.data.status = data;

    editOrderStatus(item.data);
  }, []);

  const CustomToggle = ({ children, onClick }) => (
    <Button
      disabled={item.disabled ?? false}
      onClick={(e) => {
        e.preventDefault();
        !item.disabled && onClick(e);
      }}
      className={
        "w-100 btn-status-order d-flex align-items-center justify-content-between " +
        (data.className ?? "")
      }
      style={
        statusActive?.color && {
          color: statusActive.color,
          backgroundColor: convertColor(statusActive.color, 0.08),
        }
      }
    >
      <span>{children}</span>
      <span className="ms-2">
        <IoChevronDownOutline size={15} />
      </span>
    </Button>
  );

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="status-order">
        {statusActive?.name ?? "Не указано"}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100" align="end">
        {data &&
          data.map((e, index) => (
            <Dropdown.Item
              disabled={!!statuses.find((e2) => e2.statusId === e.id)?.end}
              key={index}
              active={statusActive?.id === e?.id}
              onClick={() => onSubmit(e)}
              className="fs-08"
            >
              {e?.name ?? "Не указано"}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default Status;
