import React, { useCallback, useLayoutEffect, useState } from "react";
import { Form, Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoCheckmark } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import { editMember, getMember } from "../../services/member";
import { getModulesClient } from "../../services/module";

const MemberEdit = () => {
  const { memberId } = useParams();
  // const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [modules, setModules] = useState({
    loading: true,
    items: [],
  });
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      modules: [],
    },
  });
  const form = useWatch({ control });

  useLayoutEffect(() => {
    getMember(memberId)
      .then((res) => reset(res))
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    editMember(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      )
      .finally(() => setBtnLoading(false));
  }, []);

  const onModule = useCallback(
    (data) => {
      var array = form?.modules ?? [];
      let isFind = array.find((e) => e.id === data.id);
      if (isFind) {
        array = array.filter((e) => e.id != data.id);
      } else {
        data.price = 0;
        array.push(data);
      }
      setValue("modules", array);
    },
    [form?.modules]
  );

  useLayoutEffect(() => {
    getModulesClient()
      .then(
        (res) =>
          res &&
          setModules((prev) => ({
            ...prev,
            loading: false,
            items: res,
          }))
      )
      .finally(() => setModules((prev) => ({ ...prev, loading: false })));
  }, []);

  if (loading || modules.loading) {
    return <Loader full />;
  }

  if (!form?.id) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Нет информации о сотруднике</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title="Редактирование клиента" />
      <h3 className="mb-4">Редактирование клиента</h3>
      <Card body>
        <Row>
          <Col md={4}>
            <div className="mb-4">
              <Input
                label="Email"
                name="email"
                placeholder="Введите email"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                label="Номер телефона"
                name="phone"
                placeholder="+7(000)000-00-00"
                mask="+7(999)999-99-99"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                type="date"
                label="День рождения"
                placeholder="Введите день рождения"
                name="brithday"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                label="Фамилия"
                name="lastName"
                placeholder="Введите фамилию"
                errors={errors}
                register={register}
                validation={{
                  maxLength: {
                    value: 50,
                    message: "Максимально 50 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                label="Имя"
                name="firstName"
                errors={errors}
                register={register}
                placeholder="Введите имя"
                validation={{
                  required: "Введите имя",
                  maxLength: {
                    value: 20,
                    message: "Максимально 20 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                label="Отчество"
                name="patronymic"
                errors={errors}
                register={register}
                placeholder="Введите отчество"
                validation={{
                  maxLength: {
                    value: 50,
                    message: "Максимально 50 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <Select
              className="mb-4"
              label="Пол"
              onClick={(e) => setValue("sex", e.value)}
              value={form.sex ?? ""}
              data={[
                { title: "Не указано", value: "" },
                { title: "Мужской", value: "man" },
                { title: "Женский", value: "woman" },
              ]}
            />
          </Col>
        </Row>
        <h5 className="mb-4">Бренд и лицензия</h5>
        <Row>
          <Col md={4}>
            <div className="mb-4">
              <Input
                label="Название"
                name="brand.title"
                errors={errors}
                register={register}
                placeholder="Введите название бренда"
                validation={{
                  maxLength: {
                    value: 50,
                    message: "Максимально 50 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                label="Описание"
                name="brand.desc"
                errors={errors}
                register={register}
                placeholder="Введите описание бренда"
                validation={{
                  maxLength: {
                    value: 500,
                    message: "Максимально 500 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                readOnly={false}
                label="Токен"
                name="brand.token"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
        </Row>
        <h5 className="mb-4">Тариф и стоимость</h5>
        <Row>
          <Col md={6}>
            <Select
              className="mb-4"
              label="Тариф"
              onClick={(e) => setValue("rate", e.value)}
              value={form.rate ?? "rent"}
              data={[
                { title: "Аренда", value: "rent" },
                { title: "Процент", value: "procent" },
                { title: "Выкуп", value: "ransom" },
              ]}
            />
          </Col>
          <Col md={6}>
            <Input
              readOnly={form.rate !== "rent"}
              label="Сумма\Процент"
              name="price"
              type="number"
              errors={errors}
              register={register}
              placeholder="0"
            />
          </Col>
        </Row>
        <h5 className="mb-4">Пароль</h5>
        <Row>
          <Col md={6}>
            <div className="mb-4">
              <Input
                label="Пароль"
                name="password"
                errors={errors}
                placeholder="Введите пароль"
                register={register}
                validation={{
                  maxLength: {
                    value: 200,
                    message: "Максимально 200 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-4">
              <Input
                label="Повторный пароль"
                name="confirmPassword"
                errors={errors}
                placeholder="Введите повторно пароль"
                register={register}
                validation={{
                  maxLength: {
                    value: 200,
                    message: "Максимально 200 символов",
                  },
                }}
              />
            </div>
          </Col>
          <h5 className="mb-4">Услуги</h5>
          {modules.items.map((item) => {
            let isFind =
              form?.modules?.length > 0
                ? form.modules.findIndex((e) => e.moduleId === item.id)
                : false;
            return (
              <Col xxl={6} lg={6}>
                <div
                  className={
                    "module-card p-3" + (isFind != -1 ? " active" : "")
                  }
                  onClick={() => onModule(item)}
                >
                  <Row className="gx-3 align-items-center">
                    <Col md="auto">
                      {isFind != -1 && <IoCheckmark className="success" />}
                      <img
                        width={50}
                        height={50}
                        className="module-image"
                        src={
                          item?.media
                            ? "/images/modules/mini/" + item.media
                            : "/images/empty-product-image.png"
                        }
                      />
                    </Col>
                    <Col>
                      <p className="d-flex align-items-center mb-1">
                        <span className="fw-6 fs-08">
                          {item?.title ?? "Название"}
                        </span>
                      </p>
                      <p className="d-flex align-items-center">
                        <span className="fw-6 fs-08 text-muted">
                          {item?.desc ?? "Описание"}
                        </span>
                      </p>
                    </Col>
                  </Row>
                </div>
                {isFind != -1 && (
                  <Row>
                    <Col md={6}>
                      <div className="mt-4">
                        <Input
                          label="Стоимость"
                          name={`modules[${isFind}].price`}
                          defaultValue={0}
                          errors={errors}
                          register={register}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-4">
                        <Input
                          label="Название\Домен"
                          name={`modules[${isFind}].value`}
                          errors={errors}
                          register={register}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            );
          })}
          <h5 className="mt-4 mb-4">Демо данные</h5>
          <Col md={3}>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="demo.categories"
                id="categories"
                {...register("demo.categories")}
              />
              <Form.Check.Label htmlFor="categories" className="ms-2">
                Категории
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col md={3}>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="demo.products"
                id="products"
                {...register("demo.products")}
              />
              <Form.Check.Label htmlFor="products" className="ms-2">
                Товары
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col md={3}>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="demo.client"
                id="client"
                {...register("demo.client")}
              />
              <Form.Check.Label htmlFor="client" className="ms-2">
                Клиент
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col md={3}>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="demo.banners"
                id="banners"
                {...register("demo.banners")}
              />
              <Form.Check.Label htmlFor="banners" className="ms-2">
                Баннеры
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            className="btn btn-primary"
            isValid={isValid}
            isLoading={btnLoading}
            disabled={btnLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Сохранить изменения
          </Button>
        </div>
      </Card>
    </>
  );
};

export default MemberEdit;
