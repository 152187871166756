import { useCallback, useLayoutEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoRefreshOutline, IoSwapHorizontalOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import { getStatistics } from "../../../services/statistic";
import { getEprProduct } from "../../../services/upload";

const PublicForm = ({ data, onSubmit }) => {
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  return (
    <>
      <h5 className="mb-4 fw-7">Публикации</h5>
      <Row>
        <Col md={6}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center">
              <div>
                <img
                  src="/images/modules/public/google.svg"
                  width={80}
                  height={80}
                  className="module-image me-3"
                />
              </div>
              <div>
                <h5 className="mb-2 fw-6 h6">Google Play</h5>
                <p className="text-muted">
                  {form.relationModule.google ?? "Приложение публикуетcя..."}
                </p>
                <div className="mt-2">
                  <a
                    href=""
                    disabled={!form.relationModule.google}
                    className="btn-sm btn-primary-outline"
                  >
                    Перейти в маркет
                  </a>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col md={6}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center">
              <div>
                <img
                  src="/images/modules/public/apple.svg"
                  width={80}
                  height={80}
                  className="module-image me-3"
                />
              </div>
              <div>
                <h5 className="mb-2 fw-6 h6">Apple Store</h5>
                <p className="text-muted">
                  {form.relationModule.apple ?? "Приложение публикуетcя..."}
                </p>
                <div className="mt-2">
                  <a
                    href=""
                    disabled={!form.relationModule.apple}
                    className="btn-sm btn-primary-outline"
                  >
                    Перейти в маркет
                  </a>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col md={6}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center">
              <div>
                <img
                  src="/images/modules/public/huawei.svg"
                  width={80}
                  height={80}
                  className="module-image me-3"
                />
              </div>
              <div>
                <h5 className="mb-2 fw-6 h6">Huawei</h5>
                <p className="text-muted">
                  {form.relationModule.xiaomi ??
                    "Публикация временно недоступна"}
                </p>
                <div className="mt-2">
                  <a
                    href=""
                    disabled={!form.relationModule.google}
                    className="btn-sm btn-primary-outline"
                  >
                    Перейти в маркет
                  </a>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col md={6}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center">
              <div>
                <img
                  src="/images/modules/public/xiaomi.svg"
                  width={80}
                  height={80}
                  className="module-image me-3"
                />
              </div>
              <div>
                <h5 className="mb-2 fw-6 h6">Xiaomi</h5>
                <p className="text-muted">
                  {form.relationModule.xiaomi ??
                    "Публикация временно недоступна"}
                </p>
                <div className="mt-2">
                  <a
                    href=""
                    disabled={!form.relationModule.google}
                    className="btn-sm btn-primary-outline"
                  >
                    Перейти в маркет
                  </a>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default PublicForm;
