import "bootstrap/dist/css/bootstrap.min.css";
import React, { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "swiper/css/mousewheel";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./assets/styles/style.min.css";
import Loader from "./components/UI/Loader";
import socket from "./config/socket";
import AppRouter from "./routes/AppRouter";
import { checkAuth, logout } from "./services/auth";
import { setAuth, setUser } from "./store/reducers/authSlice";

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    if (localStorage.getItem("token")) {
      checkAuth()
        .then((data) => {
          dispatch(setUser(data));
          dispatch(setAuth(true));

          socket.connect();
          socket.emit("member/add", {
            employeeId: data.id,
          });
        })
        .catch(() => dispatch(logout()))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
    return () => {
      socket.off("connect");
    };
  }, []);

  if (loading) {
    return <Loader full />;
  }

  return <AppRouter />;
};
export default App;
