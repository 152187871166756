import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getModifiers = async (data) => {
  const response = await $authApi.get(apiRoutes.MODIFIERS, {
    params: data,
  });

  return response?.data;
};

const getModifier = async (id) => {
  const response = await $authApi.get(apiRoutes.MODIFIER, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createModifier = async (data) => {
  const response = await $authApi.post(apiRoutes.MODIFIER_CREATE, data);
  return response?.data;
};

const editModifier = async (data) => {
  const response = await $authApi.put(apiRoutes.MODIFIERS, data);
  return response?.data;
};

const deleteModifier = async (ids) => {
  const response = await $authApi.delete(apiRoutes.MODIFIERS, {
    data: { ids },
  });
  return response?.data;
};

export {
  getModifiers,
  getModifier,
  createModifier,
  editModifier,
  deleteModifier,
};
