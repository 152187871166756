import React from "react";
import { Col, Form, Row, Tab, Tabs, Card } from "react-bootstrap";
import { CirclePicker } from "react-color";
import { useForm, useWatch } from "react-hook-form";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import Textarea from "../../../components/UI/Textarea";
import Select from "../../../components/UI/Select";

// {
//   "name": "ru.totos.app",
//   "type": "app",
//   "email": "gikami.book@gmail.com",
//   "phone": "+7(843)226-80-60",
//   "title": "Toto's pizza приложение",
//   "pointQr": "phone",
//   "version": "0.0.1",
//   "authType": "phone",
//   "payments": {
//     "card": true,
//     "cash": true,
//     "online": true
//   },
//   "colorMain": "#009640",
//   "themeSale": 0,
//   "versionIos": "0.0.1",
//   "colorMainBg": "#0096402b",
//   "themeProduct": 1,
//   "cashbackOrder": 0,
//   "versionAndroid": "0.0.1",
//   "pointRegistration": 0,
//   "profilePointVisible": true,
//   "themeNavigationBottom": 0
// }

const syncAuthData = [
  {
    title: "По почте",
    value: "email",
  },
  {
    title: "По номеру телефона",
    value: "phone",
  },
];

const syncThemeTypeData = [
  {
    title: "Системная (По умолчанию)",
    value: false,
  },
  {
    title: "Темная",
    value: "dark",
  },
  {
    title: "Светлая",
    value: "light",
  },
];

const syncPointData = [
  {
    title: "Номер телефона",
    value: "phone",
  },
  {
    title: "Email",
    value: "email",
  },
  {
    title: "Идентификатор из интеграции",
    value: "apiId",
  },
];

const syncThemeProduct = [
  {
    title: "Средний размер изображения",
    value: 0,
  },
  {
    title: "Большой размер изображения",
    value: 1,
  },
];

const syncProductColumn = [
  {
    title: "Один в ряд",
    value: 0,
  },
  {
    title: "Два в ряд",
    value: 1,
  },
];

const syncThemeAddition = [
  {
    title: "Список по вертикали",
    value: 0,
  },
  {
    title: "Список по горизонтали",
    value: 1,
  },
];

const AppForm = ({ data, onSubmit }) => {
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  return (
    <Card body className="mb-4">
      <h5 className="mb-4">Настройки</h5>
      <Tabs defaultActiveKey={0} className="mb-3" fill>
        <Tab eventKey={0} title="Основное">
          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  className="mb-4"
                  label="Название"
                  name="relationModule.options.title"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.title}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  className="mb-4"
                  label="Лицензия"
                  readOnly={false}
                  defaultValue={form?.relationModule?.options?.name}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  label="Версия Android"
                  name="relationModule.options.versionAndroid"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.versionAndroid}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
                <small className="text-muted fs-07">
                  Версия должна совпадать с версией в Google Console
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  label="Версия ios"
                  name="relationModule.options.versionIos"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.versionIos}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
                <small className="text-muted fs-07">
                  Версия должна совпадать с версией в Apple Developer
                </small>
              </div>
            </Col>
          </Row>
          <h5 className="mb-4">Профиль</h5>
          <div className="mb-4">
            <Select
              label="Тип аутентификации"
              onClick={(e) =>
                setValue("relationModule.options.authType", e.value)
              }
              value={form?.relationModule?.options?.authType ?? "phone"}
              data={syncAuthData}
            />
          </div>
          <Form.Check className="mb-4">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.profilePointVisible"
              id="profilePointVisible"
              defaultChecked={
                form?.relationModule?.options?.profilePointVisible
              }
              {...register("relationModule.options.profilePointVisible")}
            />
            <Form.Check.Label htmlFor="profilePointVisible" className="ms-2">
              Показывать бонусы в профиле
            </Form.Check.Label>
          </Form.Check>
          <div className="mb-4">
            <Select
              label="Идентификатор QR"
              onClick={(e) =>
                setValue("relationModule.options.qrType", e.value)
              }
              value={form?.relationModule?.options?.qrType ?? "phone"}
              data={syncPointData}
            />
          </div>
          <Form.Check className="mb-4">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.supportVisible"
              id="supportVisible"
              defaultChecked={form?.relationModule?.options?.supportVisible}
              {...register("relationModule.options.supportVisible")}
            />
            <Form.Check.Label htmlFor="supportVisible" className="ms-2">
              Вкл\Выкл тех поддержку
            </Form.Check.Label>
          </Form.Check>
          <h5 className="mb-4">Оформление заказа</h5>
          <Form.Check className="mb-4">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.promoVisible"
              id="promoVisible"
              defaultChecked={form?.relationModule?.options?.promoVisible}
              {...register("relationModule.options.promoVisible")}
            />
            <Form.Check.Label htmlFor="promoVisible" className="ms-2">
              Вкл\Выкл промокоды
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="mb-4">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.giftVisible"
              id="giftVisible"
              defaultChecked={form?.relationModule?.options?.giftVisible}
              {...register("relationModule.options.giftVisible")}
            />
            <Form.Check.Label htmlFor="giftVisible" className="ms-2">
              Показать\Скрыть выбор подарков
            </Form.Check.Label>
          </Form.Check>
          <div className="mb-4">
            <Textarea
              label="Текст успешной отправки заказа"
              placeholder='Статус заказа вы можете узнать в личном профиле, в разделе "Заказы".'
              name="relationModule.options.checkoutSuccessText"
              errors={errors}
              register={register}
              validation={{
                maxLength: {
                  value: 1500,
                  message: "Максимально 500 символов",
                },
              }}
              defaultValue={form?.relationModule?.options?.checkoutSuccessText}
            />
          </div>
          <h5 className="mb-4">Товар</h5>
          <Form.Check className="mb-4">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.productEnergyVisible"
              id="productEnergyVisible"
              defaultChecked={
                form?.relationModule?.options?.productEnergyVisible
              }
              {...register("relationModule.options.productEnergyVisible")}
            />
            <Form.Check.Label htmlFor="productEnergyVisible" className="ms-2">
              Показать\Скрыть пищевую ценность
            </Form.Check.Label>
          </Form.Check>
        </Tab>
        <Tab eventKey={1} title="Оплата">
          <p className="mb-3 fw-6">Способы оплат</p>
          <Form.Check className="mb-4">
            <Form.Check.Input
              type="checkbox"
              name="online"
              id="online"
              defaultChecked={form?.relationModule?.options?.payments?.online}
              {...register("relationModule.options.payments.online")}
            />
            <Form.Check.Label htmlFor="online" className="ms-2">
              Онлайн оплата
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="mb-4">
            <Form.Check.Input
              type="checkbox"
              name="card"
              id="card"
              defaultChecked={form?.relationModule?.options?.payments?.card}
              {...register("relationModule.options.payments.card")}
            />
            <Form.Check.Label htmlFor="card" className="ms-2">
              Банковской картой
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="mb-4">
            <Form.Check.Input
              type="checkbox"
              name="cash"
              id="cash"
              defaultChecked={form?.relationModule?.options?.payments?.cash}
              {...register("relationModule.options.payments.cash")}
            />
            <Form.Check.Label htmlFor="cash" className="ms-2">
              Наличными
            </Form.Check.Label>
          </Form.Check>
        </Tab>
        <Tab eventKey={2} title="Оформление">
          <Row>
            <Col md={12}>
              <div className="mb-4">
                <Select
                  label="Тема"
                  onClick={(e) =>
                    setValue("relationModule.options.themeType", e.value)
                  }
                  value={form?.relationModule?.options?.themeType ?? false}
                  data={syncThemeTypeData}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <p className="mb-3 fw-6">Основной цвет</p>
                <CirclePicker
                  colors={[
                    "#009640",
                    "#E71C47",
                    "#F75C03",
                    "#F56057",
                    "#ff5252",
                    "#424965",
                    "#FF5C00",
                    "#BBB233"
                  ]}
                  color={form?.relationModule?.options?.colorMain}
                  onChangeComplete={(e) =>
                    setValue("relationModule.options.colorMain", e.hex)
                  }
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <p className="mb-3 fw-6">Цвет кнопок</p>
                <CirclePicker
                  colors={[
                    "#009640",
                    "#E71C47",
                    "#F75C03",
                    "#F56057",
                    "#ff5252",
                    "#424965",
                    "#FF5C00",
                    "#BBB233"
                  ]}
                  color={form?.relationModule?.options?.colorBtn}
                  onChangeComplete={(e) =>
                    setValue("relationModule.options.colorBtn", e.hex)
                  }
                />
              </div>
            </Col>
          </Row>
          <h5 className="mb-4">Товары</h5>
          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид товара"
                  onClick={(e) =>
                    setValue("relationModule.options.themeProduct", e.value)
                  }
                  value={form?.relationModule?.options?.themeProduct ?? 0}
                  data={syncThemeProduct}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид ряда"
                  onClick={(e) =>
                    setValue(
                      "relationModule.options.themeProductColumn",
                      e.value
                    )
                  }
                  value={form?.relationModule?.options?.themeProductColumn ?? 0}
                  data={syncProductColumn}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид допов"
                  onClick={(e) =>
                    setValue("relationModule.options.themeAddition", e.value)
                  }
                  value={form?.relationModule?.options?.themeAddition ?? 0}
                  data={syncThemeAddition}
                />
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>
      <div className="d-flex justify-content-end">
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </Card>
  );
};
export default AppForm;
