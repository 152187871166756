import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getInvoices = async (data) => {
  const response = await $authApi.get(apiRoutes.INVOICES, {
    params: data,
  });

  return response?.data;
};

const getInvoice = async (id) => {
  const response = await $authApi.get(apiRoutes.INVOICE, {
    params: {
      id,
    },
  });

  return response?.data;
};
const getPayment = async () => {
  const response = await $authApi.get(apiRoutes.PAYMENT);

  return response?.data;
};
const editInvoice = async (data) => {
  const response = await $authApi.post(apiRoutes.INVOICES, data);
  return response?.data;
};

const deleteInvoice = async (id) => {
  const response = await $authApi.delete(apiRoutes.INVOICES, {
    data: { id },
  });
  return response?.data;
};

export { getInvoices, getPayment, getInvoice, editInvoice, deleteInvoice };
