import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
// import Footer from '../components/Footer'
import Header from "../components/Header";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import Menu from "../components/Menu";

const AppLayout = () => {
  const auth = useSelector((state) => state?.auth);
  return (
    <>
      <ScrollRestoration />
      {auth.isAuth ? (
        <>
          <div className="d-flex">
            <div className="left-menu">
              <Menu />
            </div>
            <div className="right-content">
              <Header />
              <main className="admin">
                <Container>
                  <Outlet />
                </Container>
              </main>
            </div>
          </div>
        </>
      ) : (
        <Outlet />
      )}
      {/* <Footer /> */}
    </>
  );
};

export default AppLayout;
