import AcquiringForm from "./AcquiringForm";
import AppForm from "./AppForm";
import EmailForm from "./EmailForm";
import FrontpadForm from "./FrontpadFrom";
import IikoForm from "./IikoForm";
import PublicForm from "./PublicForm";
import RedirectForm from "./RedirectForm";
import RKeeperForm from "./rkeeperForm";
import SiteForm from "./SiteForm";
import SmspilotForm from "./SmspilotForm";

const FormModule = (props) => {
  if (!props?.data?.name) {
    return "Такого модуля не существует";
  }
  const forms = {
    iiko: <IikoForm {...props} />,
    rkeeper: <RKeeperForm {...props} />,
    frontpad: <FrontpadForm {...props} />,
    app: <AppForm {...props} />,
    site: <SiteForm {...props} />,
    acquiring: <AcquiringForm {...props} />,
    public: <PublicForm {...props} />,
    email: <EmailForm {...props} />,
    smspilot: <SmspilotForm {...props} />,
    redirect: <RedirectForm {...props} />,
  };
  return forms[props.data.name];
};
export default FormModule;
