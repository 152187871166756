import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getSites = async (data) => {
  const response = await $authApi.get(apiRoutes.SITES_GET, {
    params: data,
  });

  return response?.data;
};

const getSite = async (id) => {
  const response = await $authApi.get(apiRoutes.SITE_GET, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createSite = async (data) => {
  const response = await $authApi.post(apiRoutes.SITE_CREATE, data);
  return response?.data;
};

const editSite = async (data) => {
  const response = await $authApi.post(apiRoutes.SITES_GET, data);
  return response?.data;
};

const deleteSite = async (id) => {
  const response = await $authApi.delete(apiRoutes.SITES_GET, {
    data: { id },
  });
  return response?.data;
};

export { getSites, getSite, editSite, deleteSite, createSite };
