import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { editZone, getZone } from "../../services/zone";

const statusData = [
  {
    title: "В работе",
    value: 1,
  },
  {
    title: "Отключено",
    value: 0,
  },
];

const EditZone = () => {
  const { zoneId } = useParams();
  const [loading, setLoading] = useState(true);
  const affiliates = useSelector((state) => state.affiliate.data);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  useEffect(() => {
    getZone(zoneId)
      .then(
        (res) =>
          res &&
          reset((prev) => ({
            ...prev,
            ...res,
          }))
      )
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    editZone(data)
      .then(() => {
        NotificationManager.success("Зона доставки успешно обновлена");
      })
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Неизвестаня ошибка"
        )
      );
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <>
        <Meta title="Зона доставки" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Такой зоны доставки нет</h3>
        </Info>
      </>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Редактирование зоны доставки</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
      <Row>
        <Col md={6}>
          <Input
            className="mb-3"
            label="Название"
            name="title"
            errors={errors}
            defaultValue={data?.title}
            register={register}
            validation={{ required: "Обязательное поле" }}
          />
          <Row>
            <Col md={4}>
              <div className="mb-3">
                <Input
                  label="Цена доставки"
                  name="price"
                  errors={errors}
                  defaultValue={data?.price ?? 0}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <Input
                  label="Мин сумма заказа"
                  name="minPrice"
                  errors={errors}
                  defaultValue={data?.minPrice ?? 0}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <Input
                  label="Бесплатная доставка"
                  name="priceFree"
                  errors={errors}
                  defaultValue={data?.priceFree ?? 0}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Время доставки"
                  name="time"
                  errors={errors}
                  defaultValue={data?.time ?? 0}
                  register={register}
                  // validation={{ required: "Обязательное поле" }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Время работы"
                  name="work"
                  errors={errors}
                  defaultValue={data?.work}
                  register={register}
                />
              </div>
            </Col>
            <Col md={6}>
              <Select
                label="Статус"
                onClick={(e) => setValue("status", e.value)}
                value={data?.status}
                data={statusData}
              />
            </Col>
            <Col md={6}>
              <Select
                label="Филиал"
                onClick={(e) => setValue("affiliateId", e.value)}
                value={data?.affiliateId}
                data={
                  affiliates?.length > 0
                    ? affiliates.map((e) => ({ title: e.full, value: e.id }))
                    : []
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Textarea
            label="GeoJSON"
            name="data"
            rows={10}
            defaultValue={JSON.stringify(data?.data)}
          />
        </Col>
      </Row>
    </>
  );
};

export default EditZone;
