import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IoEllipsisVertical } from "react-icons/io5";
import { useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import Chat from "../../components/chat";
import { apiResponseMessages } from "../../config/api";
import { dispatchAlert, dispatchApiErrorAlert } from "../../helpers/alert";
import { editUser, getUser } from "../../services/user";

const Dialogs = () => {
  const { userId } = useParams();
  const [user, setUser] = useState({
    loading: true,
    data: {},
  });

  useEffect(() => {
    getUser(userId)
      .then((res) =>
        setUser((prev) => ({
          ...prev,
          loading: false,
          data: res,
        }))
      )
      .catch((error) => setUser((prev) => ({ ...prev, loading: false })));
  }, [userId]);

  const onSubmit = useCallback((data) => {
    editUser(data)
      .then((res) => {
        if (res.type == "SUCCESS") {
          dispatchAlert("success", apiResponseMessages.ADMIN_USER_EDIT);
        }
      })
      .catch((error) => {
        dispatchApiErrorAlert(error);
      });
  }, []);

  if (user.loading) {
    return <Loader full />;
  }

    if (!user?.data?.id) {
      return (
        <>
          <Meta title="Диалоги" />
          <Info>
            <svg
              className="mb-3"
              width="60"
              height="60"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.32"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
                fill="#999"
              />
              <path
                d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
                fill="#999"
              />
            </svg>
            <h3>Пока диалогов нет</h3>
          </Info>
        </>
      );
    }

  return (
    <>
      <Meta title="Диалоги" />
      <Card className="mb-3 dialog">
        <Row>
          <Col md={3} className="list position-relative border-right">
            <div className="p-4 position-sticky top-1">
              <h5 className="fw-7 mb-3">Диалоги</h5>
            </div>
          </Col>
          <Col>
            <div className="p-4">
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <div>
                  <h5 className="fw-7">Чат</h5>
                </div>
                <div>
                  <IoEllipsisVertical size={22} />
                </div>
              </div>
              <Chat
                data={[]}
                emptyText="Нет сообщений"
                onSubmit={(e) => onSendNote(e)}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Dialogs;
