import React from "react";
import { NavLink } from "react-router-dom";

const MenuContent = () => {
  return (
    <div className="w-100 account-menu-sub-bg">
      <nav className="account-menu-sub">
        <ul>
          <li>
            <NavLink to="banners" end>
              Баннеры
            </NavLink>
          </li>
          <li>
            <NavLink to="sales">Акции</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MenuContent;
