const Footer = ({ title, description }) => {
  return (
    <div className="text-center fs-08 text-muted mt-4">
      ©{" "}
      <a href="https://plaix.ru" target="_blank">
        Plaix
      </a>{" "}
      2023г. Все права защищены.
    </div>
  );
};

export default Footer;
