import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getModules = async () => {
  const response = await $authApi.get(apiRoutes.MODULES);
  return response?.data;
};
const getModulesClient = async () => {
  const response = await $authApi.get(apiRoutes.MODULES_CLIENT);
  return response?.data;
};
const getModule = async (moduleId) => {
  const response = await $authApi.get(apiRoutes.MODULE, {
    params: {
      moduleId,
    },
  });
  return response?.data;
};
const editModule = async (data) => {
  const response = await $authApi.put(apiRoutes.MODULES, data);
  return response?.data;
};
const createModule = async (data) => {
  const response = await $authApi.post(apiRoutes.MODULES, data);
  return response?.data;
};
const deleteModule = async (data) => {
  const response = await $authApi.delete(apiRoutes.MODULES, { data });
  return response?.data;
};

export { getModules, getModule, editModule, createModule, deleteModule, getModulesClient };
