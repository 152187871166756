import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";

const SmspilotForm = ({ data, onSubmit }) => {
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  return (
    <Card body className="mb-4">
      <h5 className="mb-4">Настройки</h5>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="Токен"
              name="relationModule.options.token"
              errors={errors}
              defaultValue={form?.relationModule?.options?.token}
              register={register}
              validation={{ required: "Обязательное поле" }}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="Отправитель"
              name="relationModule.options.from"
              placeholder="По умолчанию 'INFORM'"
              errors={errors}
              defaultValue={form?.relationModule?.options?.from}
              register={register}
            />
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </Card>
  );
};
export default SmspilotForm;
