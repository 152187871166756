import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import Select from "../../../components/UI/Select";

const emailData = [
  {
    title: "Яндекс",
    value: "yandex",
  },
  {
    title: "Mail",
    value: "mail",
  },
  {
    title: "Google",
    value: "google",
  },
];

const EmailForm = ({ data, onSubmit }) => {
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  return (
    <Card body className="mb-4">
      <h5 className="mb-4">Настройки</h5>
      <Row>
        <Col lg={12}>
          <Select
            className="mb-3"
            label="Система"
            onClick={(e) => setValue("relationModule.options.type", e.value)}
            value={form?.relationModule?.options?.type}
            data={emailData}
          />
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="Логин"
              placeholder="Введите логин"
              name="relationModule.options.login"
              errors={errors}
              defaultValue={form?.relationModule?.options?.login}
              register={register}
              validation={{ required: "Обязательное поле" }}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="Пароль"
              placeholder="Введите пароль"
              name="relationModule.options.password"
              errors={errors}
              defaultValue={form?.relationModule?.options?.password}
              register={register}
              validation={{ required: "Обязательное поле" }}
            />
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </Card>
  );
};
export default EmailForm;
