import React from "react";
// import { Badge } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Footer from "./Footer";

const AdminMenu = () => {
  return (
    <div className="w-100">
      <Link
        to="/"
        className="fs-12 fw-7 p-2 py-3 d-flex align-items-center logo"
      >
        <img src="/logo.svg" height={35} />
        {/* <span className="ms-3 fw-8">PLAIX</span> */}
      </Link>
      <nav className="account-menu position-sticky top-1 mb-4">
        <ul>
          {/* <li>
            <p className="nav-label">Общее</p>
          </li> */}
          <li>
            <NavLink to="/" end>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M21.1808 16.9703C20.8971 17.6255 20.2225 18 19.5086 18H14.8154C14.8462 17.9145 14.8735 17.8269 14.8971 17.7373C15.1709 16.6974 14.8825 15.639 14.2214 14.8963C14.4654 12.9091 14.6177 10.8733 14.7108 9.26516C14.7569 8.46731 13.7795 8.20081 13.4274 8.91526C12.7178 10.3553 11.8493 12.1958 11.0842 14.041C10.1467 14.3479 9.3768 15.1177 9.10295 16.1576C8.93642 16.7899 8.97782 17.4291 9.18451 18H4.49141C3.77747 18 3.10288 17.6255 2.81918 16.9703C2.29212 15.7533 2 14.4108 2 13C2 7.47715 6.47715 3 12 3C17.5229 3 22 7.47715 22 13C22 14.4108 21.7079 15.7533 21.1808 16.9703Z"
                  />
                  <path d="M14.7108 9.26516C14.7569 8.46731 13.7795 8.20081 13.4274 8.91526C12.7178 10.3553 11.8493 12.1958 11.0842 14.041C10.1467 14.3479 9.3768 15.1177 9.10295 16.1576C8.6742 17.7856 9.62375 19.459 11.2238 19.8953C12.8238 20.3315 14.4684 19.3654 14.8971 17.7373C15.1709 16.6974 14.8825 15.639 14.2214 14.8963C14.4654 12.9091 14.6177 10.8733 14.7108 9.26516Z" />
                </svg>
                <span>Главная</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="members">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6304 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6304 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
                  />
                  <path d="M13.9382 13.8559C15.263 13.1583 16.1663 11.768 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.5319 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z" />
                </svg>
                <span>Клиенты</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="brands">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6304 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6304 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
                  />
                  <path d="M13.9382 13.8559C15.263 13.1583 16.1663 11.768 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.5319 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z" />
                </svg>
                <span>Бренды</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="sites">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6304 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6304 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
                  />
                  <path d="M13.9382 13.8559C15.263 13.1583 16.1663 11.768 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.5319 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z" />
                </svg>
                <span>Сайты</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="apps">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6304 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6304 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
                  />
                  <path d="M13.9382 13.8559C15.263 13.1583 16.1663 11.768 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.5319 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z" />
                </svg>
                <span>Приложения</span>
              </span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="orders">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M4.32992 6C3.84871 6 3.43524 6.34385 3.34854 6.8172C1.98294 14.2724 1.75 18 1.75 18H22.25C22.25 18 22.017 14.2724 20.6514 6.8172C20.5647 6.34385 20.1513 6 19.67 6H4.32992Z"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.25 18H1.75C1.75 18 1.75 18.695 1.80122 19.6754C1.87282 21.0459 2.95622 22.0861 4.32766 22.138C5.855 22.1959 8.2782 22.25 12 22.25C15.7218 22.25 18.145 22.1959 19.6723 22.138C21.0438 22.0861 22.1272 21.0459 22.1988 19.6754C22.25 18.695 22.25 18 22.25 18Z"
                  />
                  <path d="M12 1C9.2386 1 7 3.23857 7 6H9C9 4.34314 10.3432 3 12 3C13.6568 3 15 4.34314 15 6H17C17 3.23857 14.7614 1 12 1Z" />
                </svg>
                <span>Заказы</span>
              </span>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="users">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6304 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6304 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
                  />
                  <path d="M13.9382 13.8559C15.263 13.1583 16.1663 11.768 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.5319 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z" />
                </svg>
                <span>Пользователи</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="dialogs">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.12319 2.24369C5.3177 2.12083 7.30475 2 10.5 2C13.6952 2 15.6823 2.12083 16.8768 2.24369C17.8972 2.34863 18.6398 3.10549 18.7572 4.12444C18.8797 5.18775 19 6.7933 19 9C19 11.2067 18.8797 12.8123 18.7572 13.8756C18.6398 14.8945 17.8973 15.6514 16.877 15.7563C15.822 15.8648 14.1489 15.9717 11.571 15.9952C11.1714 15.9989 10.7875 16.1592 10.507 16.4437L8.816 18.1584C8.08185 18.9029 6.8125 18.4707 6.6853 17.4328L6.55145 16.3414C6.52175 16.099 6.3197 15.9137 6.0759 15.9011C5.26545 15.859 4.62502 15.8079 4.12397 15.7564C3.10365 15.6515 2.36022 14.8945 2.24278 13.8756C2.12023 12.8123 2 11.2067 2 9C2 6.7933 2.12023 5.18775 2.24278 4.12444C2.36022 3.10549 3.10287 2.34863 4.12319 2.24369ZM7 6C6.4477 6 6 6.4477 6 7C6 7.5523 6.4477 8 7 8H14C14.5523 8 15 7.5523 15 7C15 6.4477 14.5523 6 14 6H7ZM7 10.5C6.4477 10.5 6 10.9477 6 11.5C6 12.0523 6.4477 12.5 7 12.5H11C11.5523 12.5 12 12.0523 12 11.5C12 10.9477 11.5523 10.5 11 10.5H7Z"
                  />
                  <path d="M5.99902 7C5.99902 6.4477 6.44672 6 6.99902 6H13.999C14.5513 6 14.999 6.4477 14.999 7C14.999 7.5523 14.5513 8 13.999 8H6.99902C6.44672 8 5.99902 7.5523 5.99902 7Z" />
                  <path d="M5.99902 11.5C5.99902 10.9477 6.44672 10.5 6.99902 10.5H10.999C11.5513 10.5 11.999 10.9477 11.999 11.5C11.999 12.0523 11.5513 12.5 10.999 12.5H6.99902C6.44672 12.5 5.99902 12.0523 5.99902 11.5Z" />
                  <path d="M10.0257 16.9308L10.506 16.4437C10.7866 16.1592 11.1705 15.9989 11.57 15.9952C14.1479 15.9717 15.821 15.8648 16.876 15.7563C17.8963 15.6514 18.6388 14.8945 18.7562 13.8755C18.8363 13.1809 18.9154 12.2547 18.9606 11.0808C19.4674 11.1109 19.8664 11.1462 20.1773 11.1811C20.8028 11.2515 21.2614 11.7033 21.3392 12.3279C21.4206 12.9808 21.499 13.9995 21.499 15.5C21.499 17.0005 21.4206 18.0192 21.3392 18.6721C21.2614 19.2967 20.8021 19.7486 20.1766 19.8189C19.7585 19.8659 19.1808 19.9136 18.3962 19.9483C18.1641 19.9585 17.9673 20.1252 17.9223 20.3531L17.7516 21.219C17.6396 21.7868 16.9538 22.0192 16.5197 21.6364L15.0712 20.3589C14.8029 20.1223 14.4575 19.9901 14.0999 19.9816C12.7849 19.9504 11.9009 19.884 11.3222 19.819C10.6967 19.7488 10.2366 19.2967 10.1588 18.6721C10.1066 18.2528 10.0555 17.6826 10.0257 16.9308Z" />
                </svg>
                <span>Чат</span>
              </span>
              {/* <Badge className="ms-2" bg="danger">
                8
              </Badge> */}
            </NavLink>
          </li>
          {/* <li className="mb-4">
            <NavLink to="statistics">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20.9603 6.0506C20.9106 7.03855 19.9709 7.40975 19.242 6.741C18.9106 6.4369 18.5138 6.06255 18.0418 5.60285C16.9756 6.5731 15.322 8.08585 13.3506 9.91645C12.8329 10.3972 11.992 10.3435 11.5397 9.8007L11.5394 9.8003C10.6518 8.73835 9.75755 7.6807 8.8322 6.6514C7.7509 7.4453 5.81 8.95825 3.88389 10.8844C3.39573 11.3725 2.60427 11.3725 2.11612 10.8844C1.62796 10.3962 1.62796 9.60475 2.11612 9.1166C4.00573 7.227 6.079 5.4545 8.30255 3.96314L8.306 3.96082C8.30685 3.96027 8.3066 3.96042 8.3066 3.96042C8.8024 3.6299 9.46255 3.69527 9.8839 4.1166C10.8519 5.08455 11.7265 6.14925 12.6118 7.19265C14.1169 5.80065 15.3848 4.64087 16.274 3.8314C15.8705 3.41506 15.5362 3.06006 15.26 2.75898C14.591 2.02997 14.9624 1.08998 15.9506 1.04025C17.2115 0.976795 18.5055 0.939456 19.7511 1.17232C20.3119 1.27718 20.7233 1.68863 20.8281 2.24948C21.061 3.49521 21.0238 4.78949 20.9603 6.0506Z" />
                  <g opacity="0.32">
                    <path d="M2.60946 22.9843C1.77292 22.9631 1.13928 22.4599 1.07089 21.6259C1.02993 21.1264 1 20.4398 1 19.5C1 18.5602 1.02993 17.8736 1.07089 17.3741C1.13928 16.5401 1.77292 16.0369 2.60946 16.0157C2.97545 16.0064 3.43306 16 4 16C4.56694 16 5.02455 16.0064 5.39055 16.0157C6.2271 16.0369 6.8607 16.5401 6.9291 17.3741C6.97005 17.8736 7 18.5602 7 19.5C7 20.4398 6.97005 21.1264 6.9291 21.6259C6.8607 22.4599 6.2271 22.9631 5.39055 22.9843C5.02455 22.9936 4.56694 23 4 23C3.43306 23 2.97545 22.9936 2.60946 22.9843Z" />
                    <path d="M18.8455 22.9792C17.8709 22.9408 17.1875 22.2557 17.1243 21.2823C17.0588 20.2731 17 18.6337 17 16C17 13.3663 17.0588 11.727 17.1243 10.7177C17.1875 9.74435 17.8709 9.0592 18.8455 9.02075C19.1671 9.00805 19.549 9 20 9C20.451 9 20.8329 9.00805 21.1545 9.02075C22.1291 9.0592 22.8125 9.74435 22.8757 10.7177C22.9412 11.727 23 13.3663 23 16C23 18.6337 22.9412 20.2731 22.8757 21.2823C22.8125 22.2557 22.1291 22.9408 21.1545 22.9792C20.8329 22.992 20.451 23 20 23C19.549 23 19.1671 22.992 18.8455 22.9792Z" />
                    <path d="M10.7766 22.9832C9.8427 22.9548 9.162 22.3419 9.0949 21.41C9.0422 20.6775 9 19.5936 9 18C9 16.4064 9.0422 15.3225 9.0949 14.59C9.162 13.6581 9.8427 13.0453 10.7766 13.0169C11.1122 13.0067 11.5163 13 12 13C12.4837 13 12.8878 13.0067 13.2234 13.0169C14.1573 13.0453 14.838 13.6581 14.9051 14.59C14.9578 15.3225 15 16.4064 15 18C15 19.5936 14.9578 20.6775 14.9051 21.41C14.838 22.3419 14.1573 22.9548 13.2234 22.9832C12.8878 22.9934 12.4837 23 12 23C11.5163 23 11.1122 22.9934 10.7766 22.9832Z" />
                  </g>
                </svg>
                <span>Статистика</span>
              </span>
            </NavLink>
          </li> */}
          {/* <li>
            <p className="nav-label">Возможности</p>
          </li> */}
          <li>
            <NavLink to="modules">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.77688 21.3755C2.93261 21.1957 2.36966 20.5161 2.28099 19.6575C2.14952 18.3844 2 16.0469 2 12C2 7.95305 2.14952 5.6156 2.28099 4.34251C2.36966 3.48389 2.93261 2.80424 3.77688 2.62451C5.1095 2.34081 7.6216 2 12 2C16.3784 2 18.8905 2.34081 20.2231 2.62451C21.0674 2.80424 21.6304 3.48389 21.719 4.34251C21.8505 5.6156 22 7.95305 22 12C22 16.0469 21.8505 18.3844 21.719 19.6575C21.6304 20.5161 21.0674 21.1957 20.2232 21.3755C18.8905 21.6592 16.3784 22 12 22C7.6216 22 5.1095 21.6592 3.77688 21.3755Z"
                    fill="#637381"
                  />
                  <rect
                    x="5"
                    y="5.24263"
                    width="6"
                    height="6"
                    rx="2"
                    fill="#637381"
                  />
                  <rect
                    x="12"
                    y="8.24265"
                    width="5.5"
                    height="5.5"
                    rx="2"
                    transform="rotate(-45 12 8.24265)"
                    fill="#637381"
                  />
                  <rect
                    x="5"
                    y="13.4853"
                    width="6"
                    height="6"
                    rx="2"
                    fill="#637381"
                  />
                  <rect
                    x="13"
                    y="13.4853"
                    width="6"
                    height="6"
                    rx="2"
                    fill="#637381"
                  />
                </svg>
                <span>Маркет</span>
              </span>
            </NavLink>
          </li>
        </ul>
        <Footer />
      </nav>
    </div>
  );
};

export default AdminMenu;
