import React from 'react'

const Info = ({children, className, ...props}) => {
    return (
        <div
            className={`w-100 text-center hv-100 d-flex flex-column align-items-center justify-content-center ${className ?? ''}`}
            {...props}
        >
            {children}
        </div>
    )
}

export default Info
