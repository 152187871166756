import React, { useCallback } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import { createCategory } from "../../services/category";

const CreateCategory = () => {
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmit = useCallback((data) => {
    createCategory(data)
      .then(() => {
        NotificationManager.success("Каталог успешно создан");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  return (
    <>
      <Meta title="Создать категорию" />
      <div>
        <Link
          to="/catalog/categories"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Создать категорию</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col md={8}>
              <div className="mb-3">
                <Input
                  defaultValue={data.title}
                  label="Название"
                  name="title"
                  errors={errors}
                  register={register}
                  validation={{
                    required: "Обязательное поле",
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <Input
                  defaultValue={data.priority}
                  label="Порядок"
                  name="priority"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CreateCategory;
