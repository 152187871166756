import { $api, $authApi } from ".";
import { apiRoutes } from "../config/api";

const getBrands = async (data) => {
  const response = await $authApi.get(apiRoutes.BRANDS, {
    params: data,
  });
  return response?.data;
};
const getBrand = async (brandId) => {
  const response = await $authApi.get(apiRoutes.BRAND, {
    params: {
      brandId,
    },
  });
  return response?.data;
};
const editBrand = async (data) => {
  const response = await $authApi.put(apiRoutes.BRANDS, data);
  return response?.data;
};
const mainBrand = async (id) => {
  const response = await $authApi.put(apiRoutes.BRAND_MAIN, { id });
  return response?.data;
};
const deleteBrand = async (id) => {
  const response = await $authApi.delete(apiRoutes.BRANDS, {
    data: { id },
  });
  return response?.data;
};
const createBrand = async (affiliate) => {
  const response = await $authApi.post(apiRoutes.BRAND_CREATE, affiliate);
  return response?.data;
};
export { getBrands, getBrand, editBrand, deleteBrand, createBrand, mainBrand };
