import { useCallback, useLayoutEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoRefreshOutline, IoSwapHorizontalOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import { getStatistics } from "../../../services/statistic";
import { getEprProduct } from "../../../services/upload";

const FrontpadForm = ({ data, onSubmit }) => {
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  const [product, setProduct] = useState(false);

  const [statistic, setStatistic] = useState({
    loading: true,

    uploadProducts: 0,
    products: 0,
  });

  useLayoutEffect(() => {
    getStatistics({ sync: true, product: true })
      .then((res) => setStatistic({ ...statistic, ...res, loading: false }))
      .catch(() => setStatistic({ ...statistic, loading: false }));
  }, [product]);

  const clickGetErpProduct = useCallback(() => {
    setProduct(true);
    getEprProduct(form).finally(() => setProduct(false));
  }, [form]);

  return (
    <>
      <Card body className="mb-4">
        <h5 className="mb-4">Настройки</h5>
        <div className="mb-3">
          <Input
            label="Токен"
            placeholder="Введите токен"
            name="relationModule.options.token"
            errors={errors}
            defaultValue={form?.relationModule?.options?.token}
            register={register}
            validation={{ required: "Обязательное поле" }}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
            Сохранить изменения
          </Button>
        </div>
      </Card>

      <h5 className="mb-4 fw-7">Синхронизация</h5>
      <Row>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Товары</h5>
              <Button
                isLoading={product}
                disabled={product || !form?.relationModule?.options?.token}
                onClick={() => clickGetErpProduct()}
                className="btn btn-sm btn-default"
              >
                <IoRefreshOutline size={22} className="text-gray" />
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.uploadProducts}
              <IoSwapHorizontalOutline className="text-muted fs-07 mx-2" />
              <span className="text-success">{statistic.products}</span>
            </h2>
            <div className="mt-2">
              <Link
                to="/upload/products"
                disabled={product || !form?.relationModule?.options?.token}
                className="btn-sm btn-primary-outline"
              >
                Перейти к списку
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default FrontpadForm;
