import moment from "moment";
import React, { useCallback, useLayoutEffect, useState } from "react";
import {
  Badge,
  ButtonToolbar,
  Card,
  Col,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import { moduleRatePrice } from "../../helpers/module";
import {
  createModule,
  deleteModule,
  editModule,
  getModule,
} from "../../services/module";
import FormModule from "./forms";
import CustomModal from "../../components/utils/CustomModal";

const ModuleEdit = () => {
  const { moduleId } = useParams();
  const brand = useSelector((state) => state?.brand?.active);
  const [modalDelete, setModalDelete] = useState(false);
  const [form, setForm] = useState({
    loading: true,
    data: false,
  });

  let price = moduleRatePrice({
    module: form?.data,
    relationModule: form?.data?.relationModule,
    brand,
  });

  useLayoutEffect(() => {
    getModule(moduleId)
      .then(
        (res) =>
          res &&
          setForm({
            loading: false,
            data: res,
          })
      )
      .catch(() =>
        setForm((res) => ({
          ...res,
          loading: false,
        }))
      );
  }, []);

  const onSubmit = useCallback((data) => {
    editModule(data)
      .then(() => NotificationManager.success("Модуль успешно обновлен"))
      .catch(
        (err) =>
          err && NotificationManager.error("Ошибка при сохранении модуля")
      );
  }, []);

  const onCreate = useCallback(() => {
    createModule(form.data)
      .then((res) => {
        NotificationManager.success("Модуль успешно подключен");
        setForm({
          loading: false,
          data: { ...form.data, relationModule: res },
        });
      })
      .catch(
        (err) =>
          err && NotificationManager.error("Ошибка при подключении модуля")
      );
  }, [form.data]);

  const onDelete = useCallback(() => {
    deleteModule(form.data)
      .then(() => {
        NotificationManager.success("Модуль успешно удален");
        setForm({
          loading: false,
          data: { ...form.data, relationModule: false },
        });
      })
      .catch(
        (err) => err && NotificationManager.error("Ошибка при удалении модуля")
      );
    setModalDelete(false);
  }, [form.data]);

  if (form.loading) {
    return <Loader full />;
  }

  if (!form?.data?.id) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такого модуля нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title={form.data?.title ?? "Модуль"} />
      <Row>
        <Col lg={8}>
          <Card body className="mb-4">
            <Row className="align-items-center">
              <Col md="auto">
                <img
                  width={105}
                  height={105}
                  className="module-image"
                  src={
                    form.data?.media
                      ? "/images/modules/" + form.data.media
                      : "/images/empty-product-image.png"
                  }
                />
              </Col>
              <Col>
                <h5 className="mb-2">{form.data?.title ?? "Модуль"}</h5>
                <p className="fs-09 text-gray">
                  {form.data?.description ?? "Описание отсутствует"}
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={4}>
          <Card body className="mb-4">
            {price?.status ? (
              <>
                <h5 className="mb-2">Информация</h5>
                <p className="d-flex align-items-center text-muted fs-09 mb-1">
                  Статус:{" "}
                  <Badge className="ms-2" bg="success">
                    {form.type == "one" ? "Выполнено" : "Активно"}
                  </Badge>
                </p>
                <p className="text-muted fs-09 mb-1">
                  Дата начала:{" "}
                  <span className="text-dark">
                    {moment(form.data.relationModule.start).format(
                      "DD.MM.YYYY"
                    )}
                  </span>
                </p>
                <p className="text-muted fs-09 mb-3">
                  Дата завершения:{" "}
                  <span className="text-dark">
                    {moment(form.data.relationModule.end).format("DD.MM.YYYY")}
                  </span>
                </p>
                <Button
                  className="btn btn-danger w-100"
                  onClick={() => setModalDelete(!modalDelete)}
                >
                  Отключить
                </Button>
                <CustomModal
                  title="Удаление"
                  show={modalDelete}
                  setShow={setModalDelete}
                  footer={
                    <>
                      <Button
                        className="me-3"
                        onClick={() => setModalDelete(!modalDelete)}
                      >
                        Отмена
                      </Button>
                      <Button className="btn-danger" onClick={onDelete}>
                        Удалить
                      </Button>
                    </>
                  }
                >
                  Вы точно хотите отключить данный модуль? После отключения,
                  восстановить данные будет невозможно.
                </CustomModal>
              </>
            ) : (
              <>
                {form.data?.new && (
                  <div className="mb-3">
                    <Badge bg="danger">Новинка</Badge>
                  </div>
                )}
                <div className="mb-3">
                  <h3 className="fw-7 mb-0">{price?.text}</h3>
                </div>
                {!price.free && (
                  <ButtonToolbar className="mb-4">
                    <ToggleButtonGroup
                      name="type"
                      defaultValue={form?.data?.type ?? "month"}
                      onChange={(e) =>
                        setForm({ ...form, data: { ...form.data, type: e } })
                      }
                    >
                      <ToggleButton id="month" value="month">
                        Месяц
                      </ToggleButton>
                      <ToggleButton id="one" value="one">
                        Выкуп
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ButtonToolbar>
                )}
                <Button className="btn btn-primary w-100" onClick={onCreate}>
                  Подключить
                </Button>
              </>
            )}
          </Card>
        </Col>
      </Row>
      {form.data?.relationModule?.end ? (
        <FormModule onSubmit={onSubmit} data={form.data} />
      ) : (
        <Card body>
          <h5>Отзывы</h5>
        </Card>
      )}
    </>
  );
};

export default ModuleEdit;
