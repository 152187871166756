import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getZones = async (data) => {
  const response = await $authApi.get(apiRoutes.ZONES, {
    params: data,
  });
  return response?.data;
};
const getZone = async (id) => {
  const response = await $authApi.get(apiRoutes.ZONE, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editZone = async (data) => {
  const response = await $authApi.put(apiRoutes.ZONES, data);
  return response?.data;
};
const deleteZone = async (id) => {
  const response = await $authApi.delete(apiRoutes.ZONES, {
    data: { id },
  });
  return response?.data;
};
const createZone = async (data) => {
  const response = await $authApi.post(apiRoutes.ZONES, data);
  return response?.data;
};
export { getZones, getZone, editZone, deleteZone, createZone };
