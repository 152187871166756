import React from "react";
import { Outlet } from "react-router-dom";
import MenuContent from "../components/MenuContent";

const ContentRoute = () => {
  return (
    <>
      <MenuContent />
      <Outlet />
    </>
  );
};

export default ContentRoute;
