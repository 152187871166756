import React, { useCallback, useLayoutEffect, useState } from "react";
import { Form, Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoCheckmark } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import { editSite, getSite } from "../../services/site";
import { getModulesClient } from "../../services/module";
import Textarea from "../../components/UI/Textarea";
import { getBrands } from "../../services/brand";
import DragDropFile from "../../components/DragDropFile";
const syncAuthData = [
  {
    title: "По почте",
    value: "email",
  },
  {
    title: "По номеру телефона",
    value: "phone",
  },
];

const syncThemeTypeData = [
  {
    title: "Системная (По умолчанию)",
    value: false,
  },
  {
    title: "Темная",
    value: "dark",
  },
  {
    title: "Светлая",
    value: "light",
  },
];

const syncPointData = [
  {
    title: "Номер телефона",
    value: "phone",
  },
  {
    title: "Email",
    value: "email",
  },
  {
    title: "Идентификатор из интеграции",
    value: "apiId",
  },
];
const SiteEdit = () => {
  const { siteId } = useParams();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [brands, setBrands] = useState({
    loading: true,
    items: [],
  });
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });

  useLayoutEffect(() => {
    getSite(siteId)
      .then((res) => reset(res))
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    editSite(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      )
      .finally(() => setBtnLoading(false));
  }, []);

  useLayoutEffect(() => {
    getBrands({ size: 250 })
      .then(
        (res) =>
          res &&
          setBrands((prev) => ({
            ...prev,
            loading: false,
            ...res.brands,
          }))
      )
      .finally(() => setBrands((prev) => ({ ...prev, loading: false })));
  }, []);

  if (loading || brands.loading) {
    return <Loader full />;
  }

  if (!form?.id) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Нет информации о сайте</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title="Редактирование сайт" />
      <h3 className="mb-4">Редактирование сайт</h3>
      <Card body>
        <Row>
          <Col md={6}>
            <div className="mb-4">
              <DragDropFile
                file={form.options.logo}
                type="site"
                size="1"
                onChange={(e) => console.log(e)}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-4">
              <Input
                label="Название"
                name="options.title"
                placeholder="Введите название"
                errors={errors}
                register={register}
              />
            </div>
            <div className="mb-4">
              <Input
                label="Домен"
                name="options.name"
                placeholder="yooapp.online"
                errors={errors}
                register={register}
              />
              <small className="text-muted fs-08">
                По умолчанию укажите sitename@yooapp.online
              </small>
            </div>
            <div className="mb-4">
              <Select
                label="Тип аутентификации"
                onClick={(e) => setValue("options.authType", e.value)}
                value={form?.options?.authType ?? "phone"}
                data={syncAuthData}
              />
            </div>
            <div className="mb-4">
              <Select
                label="Идентификатор QR"
                onClick={(e) => setValue("options.qrType", e.value)}
                value={form?.options?.qrType ?? "phone"}
                data={syncPointData}
              />
            </div>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="options.profilePointVisible"
                id="profilePointVisible"
                defaultChecked={form?.options?.profilePointVisible}
                {...register("options.profilePointVisible")}
              />
              <Form.Check.Label htmlFor="profilePointVisible" className="ms-2">
                Показывать бонусы в профиле
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="mb-4">
              <Select
                label="Бренд"
                onClick={(e) => {
                  setValue("brandId", e.value);
                  let memberId = brands.items.find(
                    (brand) => brand.id === e.value
                  )?.memberId;
                  memberId && setValue("memberId", memberId);
                }}
                value={form?.brandId}
                data={
                  brands?.items?.length > 0
                    ? brands.items.map((e) => ({ title: e.title, value: e.id }))
                    : []
                }
              />
              <small className="text-muted fs-08">
                Клиент id {form.memberId}
              </small>
            </div>
          </Col>
          <Col md={6}>
            <Form.Check className="mt-2 mb-4">
              <Form.Check.Input
                type="checkbox"
                name="options.supportVisible"
                id="supportVisible"
                defaultChecked={form?.options?.supportVisible}
                {...register("options.supportVisible")}
              />
              <Form.Check.Label htmlFor="supportVisible" className="ms-2">
                Вкл\Выкл тех поддержку
              </Form.Check.Label>
            </Form.Check>
          </Col>

          <h5 className="mt-3 mb-4">Оформление заказа</h5>
          <Col md={6}>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="options.promoVisible"
                id="promoVisible"
                defaultChecked={form?.options?.promoVisible}
                {...register("options.promoVisible")}
              />
              <Form.Check.Label htmlFor="promoVisible" className="ms-2">
                Вкл\Выкл промокоды
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col md={6}>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="options.giftVisible"
                id="giftVisible"
                defaultChecked={form?.options?.giftVisible}
                {...register("options.giftVisible")}
              />
              <Form.Check.Label htmlFor="giftVisible" className="ms-2">
                Показать\Скрыть выбор подарков
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col md={12}>
            <div className="mb-4">
              <Textarea
                label="Текст успешной отправки заказа"
                placeholder='Статус заказа вы можете узнать в личном профиле, в разделе "Заказы".'
                name="options.checkoutSuccessText"
                errors={errors}
                register={register}
                validation={{
                  maxLength: {
                    value: 1500,
                    message: "Максимально 500 символов",
                  },
                }}
                defaultValue={form?.options?.checkoutSuccessText}
              />
            </div>
          </Col>
          <h5 className="mt-3 mb-4">Товары</h5>
          <Col md={12}>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="options.productEnergyVisible"
                id="productEnergyVisible"
                defaultChecked={form?.options?.productEnergyVisible}
                {...register("options.productEnergyVisible")}
              />
              <Form.Check.Label htmlFor="productEnergyVisible" className="ms-2">
                Показать\Скрыть пищевую ценность
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            className="btn btn-primary"
            isValid={isValid}
            isLoading={btnLoading}
            disabled={btnLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Сохранить изменения
          </Button>
        </div>
      </Card>
    </>
  );
};

export default SiteEdit;
