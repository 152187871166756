import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getEprCategory = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_CATEGORY, data);
  return response?.data;
};
const getEprProduct = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_PRODUCT, data);
  return response?.data;
};
const getEprModifier = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_MODIFIER, data);
  return response?.data;
};
const getEprOrganizations = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_ORGANIZATION, data);
  return response?.data;
};
const getEprTypesDelivery = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_TEPES_DELIVERY, data);
  return response?.data;
};
const getEprTypesPayment = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_TYPES_PAYMENT, data);
  return response?.data;
};
const getEprTerminal = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_TERMINAL, data);
  return response?.data;
};
const getUploadCategories = async (data) => {
  console.log(data)
  const response = await $authApi.get(apiRoutes.UPLOAD_CATEGORIES, {
    params: data,
  });
  return response?.data;
};
const getUploadProducts = async (data) => {
  const response = await $authApi.get(apiRoutes.UPLOAD_PRODUCTS, {
    params: data,
  });
  return response?.data;
};
const getUploadModifiers = async (data) => {
  const response = await $authApi.get(apiRoutes.UPLOAD_MODIFIERS, {
    params: data,
  });
  return response?.data;
};

const updateUploadCategory = async (data) => {
  const response = await $authApi.put(apiRoutes.UPLOAD_CATEGORY, data);
  return response?.data;
};

const deleteUploadCategory = async (data) => {
  const response = await $authApi.delete(apiRoutes.UPLOAD_CATEGORY, {
    data: { apiIds: data },
  });
  return response?.data;
};

const updateUploadModifier = async (data) => {
  const response = await $authApi.put(apiRoutes.UPLOAD_MODIFIER, {
    apiIds: data,
  });
  return response?.data;
};

const deleteUploadModifier = async (data) => {
  const response = await $authApi.delete(apiRoutes.UPLOAD_MODIFIER, {
    data: { apiIds: data },
  });
  return response?.data;
};

const updateUploadProduct = async (data) => {
  const response = await $authApi.put(apiRoutes.UPLOAD_PRODUCT, {
    apiIds: data,
  });
  return response?.data;
};

const deleteUploadProduct = async (data) => {
  const response = await $authApi.delete(apiRoutes.UPLOAD_PRODUCT, {
    data: { apiIds: data },
  });
  return response?.data;
};

export {
  getEprCategory,
  getEprProduct,
  getEprModifier,
  getEprOrganizations,
  getEprTypesDelivery,
  getEprTypesPayment,
  getEprTerminal,
  getUploadCategories,
  getUploadProducts,
  getUploadModifiers,
  deleteUploadCategory,
  deleteUploadProduct,
  deleteUploadModifier,
  updateUploadCategory,
  updateUploadProduct,
  updateUploadModifier,
};
