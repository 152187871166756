import React, { useLayoutEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Meta from "../components/Meta";
import Login from "./auth/Login";
import socket from "../config/socket";
import { getStatistics } from "../services/statistic";
import { customPrice } from "../helpers/product";

const Admin = () => {
  const auth = useSelector((state) => state.auth);

  if (!auth.isAuth) {
    return <Login />;
  }

  const [statistic, setStatistic] = useState({
    online: 0,
    users: 0,
    products: 0,
    order: 0,
    total: 0,
    average: 0,
  });

  useLayoutEffect(() => {
    getStatistics({
      online: true,
      user: true,
      product: true,
      order: true,
    }).then((data) => setStatistic(data));

    socket.on("statistic", (data) => setStatistic(data));

    return () => socket.off("statistic");
  }, []);

  return (
    <>
      <Meta title="Главная" />
      <Row>
        <Col md={4}>
          <Card body className="mb-4">
            <h5 className="mb-0 fw-6 h6">Онлайн подключений</h5>
            <h2 className="m-0 text-success">{statistic?.online ?? 0}</h2>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <h5 className="mb-0 fw-6 h6">Всего пользователей</h5>
            <h2 className="m-0 text-success">{statistic?.users ?? 0}</h2>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <h5 className="mb-0 fw-6 h6">Всего товаров</h5>
            <h2 className="m-0 text-success">{statistic?.products ?? 0}</h2>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <h5 className="mb-0 fw-6 h6">Всего заказов</h5>
            <h2 className="m-0 text-success">{statistic?.order ?? 0}</h2>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <h5 className="mb-0 fw-6 h6">Всего выручки</h5>
            <h2 className="m-0 text-success">
              {customPrice(statistic?.total ?? 0)}
            </h2>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <h5 className="mb-0 fw-6 h6">Средний чек</h5>
            <h2 className="m-0 text-success">
              {customPrice(statistic?.average ?? 0)}
            </h2>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Admin;
