import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import {
  IoCloseOutline,
  IoEllipsisVertical,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Online from "../../components/Online";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import socket from "../../config/socket";
import { getImageURL } from "../../helpers/image";
import { deleteSite, getSites } from "../../services/site";

const Sites = () => {
  const inputRef = createRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sites, setSites] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const siteColumns = [
    {
      name: "Сайт",
      selector: "title",
      cell: (row) => (
        <Link to={"/site/" + row.id} className="d-flex align-items-center">
          <div className="position-relative me-2">
            {row.options?.logo && (
              <img
                src={getImageURL({
                  path: row.options.logo,
                  type: "all/web/logo",
                  size: false,
                })}
                width={30}
                height={30}
                className="mini-img"
              />
            )}
          </div>
          <div>
            {row?.options?.title?.length > 1
              ? row.options.title
              : "Нет названия"}
            <p className="text-muted fs-09">
              {row?.options?.name ?? "Нет домена"}
            </p>
          </div>
        </Link>
      ),
    },
    {
      width: "100px",
      name: "Цвет",
      cell: (row) =>
        row?.options?.colorMain ? (
          <span style={{ color: row.options.colorMain }}>
            {row.options.colorMain}
          </span>
        ) : (
          "Не задан"
        ),
    },
    {
      width: "35px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <Dropdown className="d-flex align-items-center">
          <Dropdown.Toggle
            as={React.forwardRef(({ children, onClick }, ref) => (
              <Link
                ref={ref}
                className="py-0"
                onClick={(e) => {
                  e.preventDefault();
                  onClick(e);
                }}
              >
                <IoEllipsisVertical size={20} />
              </Link>
            ))}
          />
          <Dropdown.Menu align="end">
            <Dropdown.Item as={Link} to={"/site/" + row.id}>
              Редактировать
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                setModalDelete({
                  show: !modalDelete.show,
                  id: row.id,
                })
              }
            >
              Удалить
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const getData = useCallback(async () => {
    getSites(searchParams)
      .then((res) => {
        setSites((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }));
      })
      .catch(() => setSites((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0 ? `Выбрано ${selected.length}` : "Сайты"}
            </h5>
          </div>
          <div>
            <Button
              disabled={selected.length === 0}
              className="btn-light"
              onClick={() => setModalDelete({ show: true, id: false })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={3}>
              <Link className="btn btn-primary w-100" to="/sites/create">
                Добавить сайт
              </Link>
            </Col>

            <Col md={9}>
              <Input
                ref={inputRef}
                placeholder="Найти"
                className="w-100"
                onChange={(e) => {
                  searchParams.set("text", e);
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                defaultValue={searchParams.get("text")}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
              {searchParams.get("text")?.length > 0 && (
                <Button
                  className="btn-light ms-3"
                  onClick={() => {
                    searchParams.delete("text");
                    setSearchParams(searchParams);
                    onSearch();
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <IoCloseOutline size={22} />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete]);

  const clickDelete = (id) => {
    deleteSite(id).finally(() => getData());
    setModalDelete({ show: false, id: false });
  };

  if (sites.loading) {
    return <Loader full />;
  }

  if (!sites.items || sites.items.length === 0) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Пока сайтов нет</h3>

        <Link className="mt-3 btn btn-primary" to="/sites/create">
          Добавить сайт
        </Link>
      </Info>
    );
  }

  return (
    <>
      <Meta title="Сайты" />
      <DataTable
        columns={siteColumns}
        onChange={(items) => setSelected(items)}
        data={sites.items}
        header={header}
        selectable
        pagination={sites.pagination}
      />
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => modalDelete.id && clickDelete(modalDelete.id)}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить?
      </CustomModal>
    </>
  );
};

export default Sites;
