import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import AuthRoute from "../layouts/AuthRoute";
import Admin from "../pages";
import NewPassword from "../pages/auth/NewPassword";
import Recovery from "../pages/auth/Recovery";
import Reg from "../pages/auth/Reg";
import DocsView from "../pages/DocsView";
import AdminRouter from "./AdminRouter";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppLayout />}>
      <Route index element={<Admin />} />
      <Route path="reg" element={<Reg />} />
      <Route path="recovery" element={<Recovery />} />
      <Route path="new-password" element={<NewPassword />} />
      <Route path="docs/:brandId/:action" element={<DocsView />} />
      <Route
        path="/*"
        element={
          <AuthRoute>
            <AdminRouter />
          </AuthRoute>
        }
      />
    </Route>
  )
);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
