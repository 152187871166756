import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import CustomModal from "./utils/CustomModal";
import getCroppedImg from "./CropperCreateImage";
import Button from "./UI/Button";

const ImageCropper = ({ file, onComplete, setShow, show, aspect = 1 }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [fileDataURL, setFileDataURL] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(fileDataURL, croppedAreaPixels);
      onComplete && onComplete(croppedImage);
      setShow && setShow(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  return (
    <CustomModal
      size="lg"
      title="Редактор изображения"
      show={show}
      setShow={(e) => setShow && setShow(e)}
      classNameBody="p-0"
      footer={
        <>
          <Button
            className=" me-3"
            onClick={() => setShow && setShow(false)}
          >
            Отмена
          </Button>
          <Button
            className="btn-primary"
            onClick={() => onComplete && showCroppedImage()}
          >
            Сохранить изображение
          </Button>
        </>
      }
    >
      <div className="editor-image">
        <Cropper
          image={fileDataURL}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
    </CustomModal>
  );
};

export default ImageCropper;
