import React, { useCallback, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import ImageCropper from "../../components/Cropper";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import Textarea from "../../components/UI/Textarea";
import { createBanner } from "../../services/banner";

const BannerCreate = () => {
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const [editImageBanner, setEditImageBanner] = useState({
    show: false,
    data: [],
  });

  const onSubmit = useCallback((data) => {
    createBanner(data)
      .then(() => {
        NotificationManager.success("Баннер успешно создан");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  return (
    <>
      <Meta title="Создать баннер" />
      <div>
        <Link
          to="/content/banners"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Создать баннер</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  defaultValue={0}
                  label="Порядок"
                  name="priority"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={6}>
              <Form.Check className="my-2">
                <Form.Check.Input
                  type="checkbox"
                  name="status"
                  id="status"
                  defaultChecked={true}
                  {...register("status")}
                />
                <Form.Check.Label htmlFor="status" className="ms-2">
                  Показать\Скрыть баннер
                </Form.Check.Label>
              </Form.Check>
            </Col>
            <Col md={12}>
              <DragDropFile
                file={data.medias}
                onChange={(e) =>
                  setEditImageBanner((info) => ({
                    show: !info.show,
                    data: e,
                  }))
                }
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ImageCropper
        file={editImageBanner.data[0]}
        show={editImageBanner.show}
        aspect={8.8 / 3.5}
        setShow={(e) => setEditImageBanner((info) => ({ ...info, show: e }))}
        onComplete={(e) => {
          e && setValue("file", e.file);
          e && setValue("medias", e.blob);
        }}
      />
    </>
  );
};

export default BannerCreate;
