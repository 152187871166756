import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getProducts = async (data) => {
  const response = await $authApi.get(apiRoutes.PRODUCTS, {
    params: data,
  });

  return response?.data;
};

const getProduct = async (id) => {
  const response = await $authApi.get(apiRoutes.PRODUCT, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createProduct = async (data) => {
  const response = await $authApi.postForm(apiRoutes.PRODUCT_CREATE, data);
  return response?.data;
};

const editProduct = async (data) => {
  const response = await $authApi.postForm(apiRoutes.PRODUCTS, data);
  return response?.data;
};

const deleteProduct = async (ids) => {
  const response = await $authApi.delete(apiRoutes.PRODUCTS, {
    data: { ids },
  });
  return response?.data;
};

export { getProducts, getProduct, createProduct, editProduct, deleteProduct };
