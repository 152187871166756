import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getIngredients = async (data) => {
  const response = await $authApi.get(apiRoutes.INGREDIENTS, {
    params: data,
  });

  return response?.data;
};

const getIngredient = async (id) => {
  const response = await $authApi.get(apiRoutes.INGREDIENT, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createIngredient = async (data) => {
  const response = await $authApi.post(apiRoutes.INGREDIENT_CREATE, data);
  return response?.data;
};

const editIngredient = async (data) => {
  const response = await $authApi.put(apiRoutes.INGREDIENTS, data);
  return response?.data;
};

const deleteIngredient = async (ids) => {
  const response = await $authApi.delete(apiRoutes.INGREDIENTS, {
    data: { ids },
  });
  return response?.data;
};

export { getIngredients, getIngredient, createIngredient, editIngredient, deleteIngredient };
