import moment from "moment";
import { memo, useEffect, useState } from "react";

const Date = ({ date }) => {
  const [dateNow, setDateNow] = useState(
    moment(date).subtract(10, "seconds").fromNow()
  );

  const updateTime = () =>
    setDateNow(moment(date).subtract(10, "seconds").fromNow());

  useEffect(() => {
    const interval = setInterval(updateTime, 60000);
    return () => clearInterval(interval);
  }, []);

  return dateNow;
};

const Online = memo(({ data }) => {
  return data?.online?.status ? (
    <p className="text-success fs-08">Онлайн</p>
  ) : data?.online?.end ? (
    <p className="text-muted fs-08">
      <Date date={data.online.end} />
    </p>
  ) : (
    <p className="text-muted fs-08">Не в сети</p>
  );
});
export default Online;
