import React, { useCallback, useLayoutEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { getBrands } from "../../services/brand";
import { createSite } from "../../services/site";
const syncAuthData = [
  {
    title: "По почте",
    value: "email",
  },
  {
    title: "По номеру телефона",
    value: "phone",
  },
];

const syncThemeTypeData = [
  {
    title: "Системная (По умолчанию)",
    value: false,
  },
  {
    title: "Темная",
    value: "dark",
  },
  {
    title: "Светлая",
    value: "light",
  },
];

const syncPointData = [
  {
    title: "Номер телефона",
    value: "phone",
  },
  {
    title: "Email",
    value: "email",
  },
  {
    title: "Идентификатор из интеграции",
    value: "apiId",
  },
];
const SiteCreate = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [brands, setBrands] = useState({
    loading: true,
    items: [],
  });
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const form = useWatch({ control });

  const onSubmit = useCallback((data) => {
    if (!data?.brandId) {
      return NotificationManager.error("Нет бренда");
    }
    setBtnLoading(true);
    createSite(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch(() => NotificationManager.error("Ошибка при сохранении"))
      .finally(() => setBtnLoading(false));
  }, []);

  useLayoutEffect(() => {
    getBrands({ size: 250 })
      .then(
        (res) =>
          res &&
          setBrands((prev) => ({
            ...prev,
            loading: false,
            ...res.brands,
          }))
      )
      .finally(() => setBrands((prev) => ({ ...prev, loading: false })));
  }, []);

  if (brands.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Создание сайта" />
      <h3 className="mb-4">Создание сайт</h3>
      <Card body>
        <Row>
          {/* <Col md={6}>
            <div className="mb-4">
              <DragDropFile type="site" size="1" />
            </div>
          </Col> */}
          <Col md={6}>
            <div className="mb-4">
              <Input
                label="Название"
                name="options.title"
                placeholder="Введите название"
                errors={errors}
                register={register}
                validation={{
                  required: "Введите название",
                  maxLength: {
                    value: 100,
                    message: "Максимально 100 символов",
                  },
                }}
              />
            </div>
            <div className="mb-4">
              <Input
                label="Домен"
                name="options.name"
                placeholder="yooapp.online"
                errors={errors}
                register={register}
                validation={{
                  required: "Введите название",
                  maxLength: {
                    value: 100,
                    message: "Максимально 100 символов",
                  },
                }}
              />
              <small className="text-muted fs-08">
                По умолчанию укажите sitename.yooapp.online
              </small>
            </div>
            <div className="mb-4">
              <Select
                label="Тип аутентификации"
                onClick={(e) => setValue("options.authType", e.value)}
                value={form?.options?.authType ?? "phone"}
                data={syncAuthData}
              />
            </div>
            <div className="mb-4">
              <Select
                label="Идентификатор QR"
                onClick={(e) => setValue("options.qrType", e.value)}
                value={form?.options?.qrType ?? "phone"}
                data={syncPointData}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className="mb-4">
              <Select
                label="Бренд"
                onClick={(e) => {
                  setValue("brandId", e.value);
                  let memberId = brands.items.find(
                    (brand) => brand.id === e.value
                  )?.memberId;
                  memberId && setValue("memberId", memberId);
                }}
                value={form?.brandId}
                data={
                  brands?.items?.length > 0
                    ? brands.items.map((e) => ({ title: e.title, value: e.id }))
                    : []
                }
              />
              <small className="text-muted fs-08">
                Клиент id {form.memberId}
              </small>
            </div>
            <Form.Check className="mt-2 mb-4">
              <Form.Check.Input
                type="checkbox"
                name="options.supportVisible"
                id="supportVisible"
                {...register("options.supportVisible")}
              />
              <Form.Check.Label htmlFor="supportVisible" className="ms-2">
                Вкл\Выкл тех поддержку
              </Form.Check.Label>
            </Form.Check>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="options.profilePointVisible"
                id="profilePointVisible"
                {...register("options.profilePointVisible")}
              />
              <Form.Check.Label htmlFor="profilePointVisible" className="ms-2">
                Показывать бонусы в профиле
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <hr />
          <h5 className="mt-3 mb-4">Оформление заказа</h5>
          <Col md={6}>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="options.promoVisible"
                id="promoVisible"
                {...register("options.promoVisible")}
              />
              <Form.Check.Label htmlFor="promoVisible" className="ms-2">
                Вкл\Выкл промокоды
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col md={6}>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="options.giftVisible"
                id="giftVisible"
                {...register("options.giftVisible")}
              />
              <Form.Check.Label htmlFor="giftVisible" className="ms-2">
                Показать\Скрыть выбор подарков
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col md={12}>
            <div className="mb-4">
              <Textarea
                label="Текст успешной отправки заказа"
                placeholder='Статус заказа вы можете узнать в личном профиле, в разделе "Заказы".'
                name="options.checkoutSuccessText"
                errors={errors}
                register={register}
                validation={{
                  maxLength: {
                    value: 1500,
                    message: "Максимально 500 символов",
                  },
                }}
              />
            </div>
          </Col>
          <hr />
          <h5 className="mt-3 mb-4">Товары</h5>
          <Col md={12}>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="options.productEnergyVisible"
                id="productEnergyVisible"
                {...register("options.productEnergyVisible")}
              />
              <Form.Check.Label htmlFor="productEnergyVisible" className="ms-2">
                Показать\Скрыть пищевую ценность
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            className="btn btn-primary"
            isValid={isValid}
            isLoading={btnLoading}
            disabled={btnLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Сохранить изменения
          </Button>
        </div>
      </Card>
    </>
  );
};

export default SiteCreate;
