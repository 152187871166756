import { apiRoutes } from "../config/api";
import { $authApi } from "./index";

const getProductParams = async (page, limit, productId) => {
  const response = await $authApi.get(apiRoutes.ADMIN_PRODUCT_PARAMS_GET, {
    params: {
      page,
      limit,
      productId,
    },
  });
  return response?.data;
};
const getProductAdditions = async (page, limit, productId) => {
  const response = await $authApi.get(apiRoutes.ADMIN_PRODUCT_ADDITIONS_GET, {
    params: {
      page,
      limit,
      productId,
    },
  });
  return response?.data;
};
const getAffiliates = async (page, limit) => {
  const response = await $authApi.get(apiRoutes.ADMIN_PICKUPS_GET, {
    params: {
      page,
      limit,
    },
  });
  return response?.data;
};
const getAffiliate = async (id) => {
  const response = await $authApi.get(apiRoutes.ADMIN_PICKUP_GET, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editAffiliate = async (affiliate) => {
  const response = await $authApi.post(apiRoutes.ADMIN_PICKUP_EDIT, affiliate);
  return response?.data;
};
const deleteAffiliate = async (id) => {
  const response = await $authApi.delete(apiRoutes.ADMIN_PICKUP_DELETE, {
    data: { id },
  });
  return response?.data;
};
const createAffiliate = async (affiliate) => {
  const response = await $authApi.post(
    apiRoutes.ADMIN_PICKUP_CREATE,
    affiliate
  );
  return response?.data;
};

const getProducts = async (page, limit, query, filter) => {
  const response = await $authApi.get(apiRoutes.ADMIN_PRODUCTS_GET, {
    params: {
      page,
      limit,
      query,
      filter,
    },
  });
  return response?.data;
};

const getProduct = async (id) => {
  try {
    const response = await $authApi.get(apiRoutes.ADMIN_PRODUCT_GET, {
      params: {
        id,
      },
    });

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
const editProduct = async (product) => {
  try {
    const formData = new FormData();
    for (var key in product) {
      if (key == "images") {
        formData.append(key, product[key][0]);
      } else {
        formData.append(
          key,
          Array.isArray(product[key])
            ? JSON.stringify(product[key])
            : product[key]
        );
      }
    }
    const response = await $authApi.post(
      apiRoutes.ADMIN_PRODUCT_EDIT,
      formData
    );

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
const deleteProduct = async (id) => {
  try {
    const response = await $authApi.delete(apiRoutes.ADMIN_PRODUCT_DELETE, {
      data: { id },
    });

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
const createProduct = async (product) => {
  try {
    const formData = new FormData();
    for (var key in product) {
      if (key == "images") {
        formData.append(key, product[key][0]);
      } else {
        formData.append(key, product[key]);
      }
    }

    const response = await $authApi.post(
      apiRoutes.ADMIN_PRODUCT_CREATE,
      formData
    );

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

const getNotifications = async (page, limit) => {
  try {
    const response = await $authApi.get(apiRoutes.ADMIN_NOTIFICATIONS_GET, {
      params: {
        page,
        limit,
      },
    });

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
const createNotification = async (notification) => {
  try {
    const response = await $authApi.post(
      apiRoutes.ADMIN_NOTIFICATION_CREATE,
      notification
    );

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
const deleteNotification = async (id) => {
  try {
    const response = await $authApi.delete(
      apiRoutes.ADMIN_NOTIFICATION_DELETE,
      { data: { id } }
    );

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

const createMark = async (mark) => {
  try {
    const response = await $authApi.post(apiRoutes.ADMIN_MARK_CREATE, mark);

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
const getMarks = async (page, limit) => {
  try {
    const response = await $authApi.get(apiRoutes.ADMIN_MARKS_GET, {
      params: {
        page,
        limit,
      },
    });

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
const getMark = async (id) => {
  try {
    const response = await $authApi.get(apiRoutes.ADMIN_MARK_GET, {
      params: {
        id,
      },
    });

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
const editMark = async (mark) => {
  try {
    const response = await $authApi.post(apiRoutes.ADMIN_MARK_EDIT, mark);

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
const deleteMark = async (id) => {
  try {
    const response = await $authApi.delete(apiRoutes.ADMIN_MARK_DELETE, {
      data: { id },
    });

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

const getStatistic = async () => {
  try {
    const response = await $authApi.get(apiRoutes.ADMIN_STATISTIC_GET);

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

export {
  getProducts,
  getProduct,
  editProduct,
  createProduct,
  deleteProduct,
  getStatistic,
  createNotification,
  deleteNotification,
  getNotifications,
  createMark,
  getMarks,
  getMark,
  editMark,
  deleteMark,
  getAffiliates,
  getAffiliate,
  editAffiliate,
  createAffiliate,
  deleteAffiliate,
  getProductParams,
  getProductAdditions,
};
