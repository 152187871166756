const generateKey = (length = 20, type) => {
      let chars =
            type == "number"
                  ? "0123456789"
                  : type == "letters"
                        ? "abcdefghijklmnopqrstuvwxyz"
                        : "0123456789abcdefghijklmnopqrstuvwxyz";
      let key = "";
      for (var i = 1; i <= length; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            key += chars.substring(randomNumber, randomNumber + 1);
      }
      return key;
};

export { generateKey };
