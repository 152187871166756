const modulePrice = (value, type = true) => {
  if (!value && !type) {
    return 0 + "\u00A0₽";
  }
  value = parseInt(value).toLocaleString() + "\u00A0₽";
  if (type == "month") {
    value = value + "/мес";
  } else if (type == "free") {
    value = "Бесплатно";
  } else if (type == "soon") {
    value = "Скоро";
  }
  return value;
};

const moduleRatePrice = ({ module, relationModule, brand }) => {
  let prices =
    module?.price instanceof Object && brand?.relationRate?.rate?.name
      ? module.price[brand.relationRate.rate.name]
      : module.price;

  let month = Array.isArray(prices)
    ? prices.find((e) => e.type == "month")?.price ?? 0
    : false;
  let one = Array.isArray(prices)
    ? prices.find((e) => e.type == "one")?.price ?? 0
    : false;

  let status = !!relationModule?.end;
  let value =
    relationModule?.price > 0
      ? relationModule.price
      : relationModule?.type && Array.isArray(prices)
      ? prices.find((e) => e.type == relationModule.type)?.price
      : 0;

  let free = false;

  if (!month && !one) {
    free = true;
  }

  if (month > 0) {
    month = "от " + modulePrice(month, "month");
  } else {
    month = modulePrice(0, "free");
  }

  if (one > 0) {
    one = modulePrice(one, "one");
  } else {
    one = modulePrice(0, "free");
  }

  let text = status ? "Подключено" : month ?? one ?? 0;

  return { month, one, status, value, text, free };
};

const moduleBgColor = {
  month: "primary",
  one: "primary",
  soon: "danger",
  free: "secondary",
};

export { modulePrice, moduleRatePrice, moduleBgColor };
