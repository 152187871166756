import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Container,
  Dropdown,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { IoNotificationsOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import useIsMobile from "../hooks/isMobile";
// import MobileNav from "./MobileNav";
import { logout } from "../services/auth";
import Button from "./UI/Button";
import CustomModal from "./utils/CustomModal";

const Date = () => {
  const [date, setDate] = useState(moment().format("DD MMM YYYY kk:mm:ss"));

  const updateTime = () => setDate(moment().format("DD MMM YYYY kk:mm:ss"));

  useEffect(() => {
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  return date;
};

const Header = () => {
  // const isMobile = useIsMobile();
  const state = useSelector((state) => state);

  const [isShowBurgerMenu, setShowBurgerMenu] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeBurgerMenu = useCallback(() => setShowBurgerMenu(false), []);
  const [showLogout, setShowLogout] = useState(false);

  return (
    <>
      <header>
        <Container fluid className="h-100">
          <Navbar collapseOnSelect expand="lg">
            <Container className="p-0">
              <Navbar.Toggle aria-controls="navbar-dark-example" />
              <Navbar.Collapse id="navbar-dark-example">
                <Nav className="me-auto my-2 my-lg-0">
                  <Nav.Link className="nav-date">
                    <Date />
                  </Nav.Link>
                </Nav>
                <Nav className="d-flex">
                  <Dropdown className="d-flex align-items-center">
                    <Dropdown.Toggle
                      as={React.forwardRef(({ children, onClick }, ref) => (
                        <Nav.Link
                          ref={ref}
                          className="py-0"
                          onClick={(e) => {
                            e.preventDefault();
                            onClick(e);
                          }}
                        >
                          <img
                            src="/images/lang/ru.svg"
                            height={20}
                            className="language"
                          />
                        </Nav.Link>
                      ))}
                    />
                    <Dropdown.Menu align="end">
                      <Dropdown.Item
                        as={Link}
                        to="/account"
                        className="d-flex align-items-center flex-row fs-09"
                      >
                        <img
                          src="/images/lang/ru.svg"
                          height={17}
                          width={24}
                          className="me-2"
                        />
                        Русский
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/account"
                        className="d-flex align-items-center flex-row fs-09"
                      >
                        <img
                          src="/images/lang/en.svg"
                          height={17}
                          width={24}
                          className="me-2"
                        />
                        English
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/account"
                        className="d-flex align-items-center flex-row fs-09"
                      >
                        <img
                          src="/images/lang/de.svg"
                          height={17}
                          width={24}
                          className="me-2"
                        />
                        Germany
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/account"
                        className="d-flex align-items-center flex-row fs-09"
                      >
                        <img
                          src="/images/lang/fr.svg"
                          height={17}
                          width={24}
                          className="me-2"
                        />
                        France
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle
                      as={React.forwardRef(({ children, onClick }, ref) => (
                        <Nav.Link
                          ref={ref}
                          onClick={(e) => {
                            e.preventDefault();
                            onClick(e);
                          }}
                        >
                          <IoNotificationsOutline size={24} />
                          {state?.auth?.user?.notificationCount > 0 && (
                            <Badge pill className="ms-2" bg="danger">
                              {state.auth.user.notificationCount}
                            </Badge>
                          )}
                        </Nav.Link>
                      ))}
                    />
                    <Dropdown.Menu align="end">
                      <div className="notification-menu">
                        <p className="fw-7 fs-09">Уведомления</p>
                        <p className="text-muted fs-09">
                          У вас нет новых уведомлений
                        </p>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="d-flex align-items-center">
                    <Dropdown.Toggle
                      as={React.forwardRef(({ children, onClick }, ref) => (
                        <Nav.Link
                          ref={ref}
                          className="py-0"
                          onClick={(e) => {
                            e.preventDefault();
                            onClick(e);
                          }}
                        >
                          <img src="/images/avatar.png" height={35} />
                        </Nav.Link>
                      ))}
                    />
                    <Dropdown.Menu align="end">
                      <div className="account-menu">
                        <p className="fw-7 fs-09">
                          {state.auth.user.firstName} {state.auth.user.lastName}
                        </p>
                        <p className="text-muted fs-09">
                          ID {state.auth.user.id}
                        </p>
                      </div>
                      <Dropdown.Divider className="dashed" />
                      <Dropdown.Item as={Link} to="/account">
                        Профиль
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/payment">
                        Оплата
                      </Dropdown.Item>
                      <Dropdown.Divider className="dashed" />
                      <Dropdown.Item onClick={() => setShowLogout(!showLogout)}>
                        Выход
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
      </header>
      {/* 
      <MobileNav /> */}

      <Offcanvas show={isShowBurgerMenu} onHide={closeBurgerMenu}>
        <Offcanvas.Body>
          <Container className="h-100 d-flex flex-column justify-content-between">
            <div>
              <nav className="mobile-menu-left">
                <ul className="list-unstyled">
                  {!state.auth.isAuth && (
                    <li>
                      <Button
                        type="button"
                        onClick={() => {
                          navigate("/");
                          closeBurgerMenu();
                        }}
                      >
                        Войти
                      </Button>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
            <div className="mt-4 mt-sm-5">
              <div className="light-gray fs-09">
                <a href="/">Политика обработки персональных данных</a>
              </div>
            </div>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <CustomModal
        show={showLogout}
        setShow={setShowLogout}
        title="Подтвердите действие"
        footer={
          <>
            <Button
              type="button"
              className="btn-default"
              onClick={() => setShowLogout(!showLogout)}
            >
              Отмена
            </Button>
            <Button
              type="button"
              className="btn-primary"
              onClick={() => {
                dispatch(logout());
                navigate("/login");
              }}
            >
              Выйти
            </Button>
          </>
        }
      >
        Вы точно хотите выйти?
      </CustomModal>
    </>
  );
};

export default Header;
