import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { deleteNotification, getNotifications } from "../../services/admin";

const Notifications = () => {
  const [notifications, setNotifications] = useState({
    isLoaded: false,
    error: null,
    items: [],
    pagination: false,
  });
  const [modalDelete, setModalDelete] = useState({
    isShow: false,
    id: false,
  });
  const [limit, setLimit] = useState(25);
  const notificationColumns = [
    {
      name: "Заголовок",
      selector: "title",
    },
    {
      name: "Отправлено",
      selector: "notifications",
      width: "120px",
      center: true,
      cell: (row) => row.notifications && row.notifications.length,
    },
    {
      name: "Просмотрено",
      selector: "notifications",
      center: true,
      width: "130px",
      cell: (row) =>
        row.notifications &&
        row.notifications.filter((e) => e.status === 0).length,
    },
    {
      name: "Время отправки",
      selector: "createdAt",
      width: "160px",
      right: true,
      sortable: true,
      cell: (row) => moment(row.createdAt).format("DD.MM.YYYY kk:mm"),
    },
    {
      selector: "action",
      center: true,
      width: "60px",
      cell: (row) => (
        <a
          onClick={() =>
            setModalDelete({ isShow: !modalDelete.isShow, id: row.id })
          }
        >
          <IoTrashOutline size={20} color="#ff5252" />
        </a>
      ),
    },
  ];
  const getData = (page) => {
    getNotifications(page, limit)
      .then(
        (res) =>
          res &&
          setNotifications((prev) => ({
            ...prev,
            isLoaded: true,
            items: res?.notifications?.rows,
            pagination: res.pagination,
          }))
      )
      .finally(() => setNotifications((prev) => ({ ...prev, isLoaded: true })));
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newLimit, page) => {
    setLimit(newLimit);
    getNotifications(page, newLimit)
      .then(
        (res) =>
          res &&
          setNotifications((prev) => ({
            ...prev,
            isLoaded: true,
            items: res?.notifications?.rows,
            pagination: res.pagination,
          }))
      )
      .finally(() => setNotifications((prev) => ({ ...prev, isLoaded: true })));
  };

  useEffect(() => {
    getData();
  }, []);

  const clickDelete = (id) => {
    deleteNotification(id).then(() => getData());
    setModalDelete({ isShow: false, id: false });
  };

  if (!notifications.isLoaded) {
    return <Loader full />;
  }

  if (!notifications.items || notifications.items.length === 0) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Уведомлений нет</h3>
      </Info>
    );
  }

  return (
    <section className="notifications">
      <div className="d-flex flex-row justify-content-between align-items-center mb-4">
        <h1 className="m-0">Уведомления</h1>
        <Link to="/admin/notification/create" className="btn-primary">
          Добавить
        </Link>
      </div>
      <DataTable
        columns={notificationColumns}
        data={notifications.items}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        pagination={notifications.pagination}
        expandableRows
        expandableRowsComponent={({ data }) => (
          <div className="p-4">
            <h3>{data.title}</h3>
            {data.desc}
          </div>
        )}
      />
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        isShow={modalDelete.isShow}
        setShow={(e) => setModalDelete({ isShow: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ isShow: !modalDelete.isShow, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => modalDelete.id && clickDelete(modalDelete.id)}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить уведомление?
      </CustomModal>
    </section>
  );
};

export default Notifications;
