import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Badge, Form } from "react-bootstrap";
import { IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useParams, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { deleteSale, getSales } from "../../services/sale";

const Sales = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sales, setSales] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const saleColumns = [
    {
      name: "Фото",
      width: "60px",
      selector: "media",
      cell: (row) => (
        <img
          src={getImageURL({ path: row?.medias, type: "sale" })}
          width={30}
          height={30}
          className="mini-img"
        />
      ),
    },
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Описание",
      selector: "desc",
    },
    {
      width: "100px",
      name: "Порядок",
      selector: "priority",
    },
    {
      width: "100px",
      name: "Статус",
      selector: "status",
      cell: (row) =>
        row.status === 0 ? (
          <Badge bg="secondary">Архив</Badge>
        ) : (
          <Badge bg="success">Активно</Badge>
        ),
    },
    {
      width: "80px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link to={"/content/sale/" + row.id} className="me-4">
            <IoCreateOutline size={22} />
          </Link>
          <a
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const header = useMemo(() => {
    return selected.length > 0 ? (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h5 className="fw-7 me-3">Выбрано {selected.length}</h5>
        </div>
        <div className="d-flex align-items-center">
          <div>
            <Button
              className="btn-light"
              onClick={() => setModalDelete({ show: !modalDelete.show })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h5 className="fw-7 me-3">Акции</h5>
          <Link to="/content/sales/create" className="btn-primary-outline">
            Добавить акцию
          </Link>
        </div>
        {/* <div className="d-flex align-items-center">
          <Form.Control placeholder="Поиск..." />
        </div> */}
      </div>
    );
  }, [selected]);

  const getData = useCallback(async () => {
    getSales(searchParams)
      .then(
        (res) =>
          res &&
          setSales((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setSales((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deleteSale(id)
      .then(() => {
        getData();
        NotificationManager.success("Акция успешно удалена");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteSale(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные акции успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  if (sales.loading) {
    return <Loader full />;
  }

  if (!sales.items || sales.items.length === 0) {
    return (
      <>
        <Meta title="Акции" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Пока акций нет</h3>
          <Link className="mt-3 btn btn-primary" to="/content/sales/create">
            Добавить акцию
          </Link>
        </Info>
      </>
    );
  }

  return (
    <>
      <Meta title="Акции" />
      <DataTable
        columns={saleColumns}
        onChange={(items) => setSelected(items)}
        data={sales.items}
        header={header}
        selectable
        linkPagination="/sales/"
        statusBackground="sale"
        pagination={sales.pagination}
      />
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : "Удаление элемента"
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className="me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить акцию(-ии)?
      </CustomModal>
    </>
  );
};

export default Sales;
