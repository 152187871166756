import React, { useLayoutEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import { Link, useParams, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { deleteZone, getZones } from "../../services/zone";
import { customPrice } from "../../helpers/product";

const Zones = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [zones, setZones] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    isShow: false,
    id: false,
  });

  const zoneColumns = [
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Стоимость",
      selector: "price",
      cell: (row) => customPrice(row.price),
    },
    {
      name: "Бесп-ая доставки",
      selector: "priceFree",
      cell: (row) => customPrice(row.priceFree),
    },
    {
      name: "Мин-ая сумма",
      selector: "minPrice",
      cell: (row) => customPrice(row.minPrice),
    },
    {
      name: "Время",
      selector: "time",
      cell: (row) => row.time + " мин",
    },
    {
      name: "Работа до",
      selector: "work",
    },
    {
      name: "Статус",
      selector: "status",
      cell: (row) => (row.status === 1 ? "Активно" : "Отключено"),
    },
    {
      width: "35px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <Link to={"/options/zone/" + row.id}>
          <IoCreateOutline size={22} />
        </Link>
      ),
    },
  ];

  const header = useMemo(() => {
    return selected.length > 0 ? (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h5 className="fw-7 me-3">Выбрано {selected.length}</h5>
        </div>
        <div className="d-flex align-items-center">
          <div>
            <Button className="btn-light">
              <IoTrashOutline />
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h5 className="fw-7 me-3">Зоны доставок</h5>
          <Link to="create" className="btn-primary-outline">
            Добавить зону
          </Link>
        </div>
        {/* <div className="d-flex align-items-center">
          <Form.Control placeholder="Поиск..." className="me-3" />
          <div>
            <Button className="btn-primary-outline">Статистика</Button>
          </div>
        </div> */}
      </div>
    );
  }, [selected]);

  const getData = async () => {
    getZones(searchParams)
      .then(
        (res) =>
          res &&
          setZones((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setZones((prev) => ({ ...prev, loading: false })));
  };

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  const clickDelete = (id) => {
    deleteZone(id).then(() => getData());
    setModalDelete({ isShow: false, id: false });
  };

  if (zones.loading) {
    return <Loader full />;
  }

  if (!zones.items || zones.items.length === 0) {
    return (
      <>
        <Meta title="Зоны доставок" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Пока зон доставок нет</h3>
          <Link to="create" className="mt-3 btn btn-primary">
            Добавить зону
          </Link>
        </Info>
      </>
    );
  }

  return (
    <>
      <Meta title="Зоны доставок" />
      <DataTable
        columns={zoneColumns}
        onChange={(items) => setSelected(items)}
        data={zones.items}
        header={header}
        selectable
        pagination={zones.pagination}
      />
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        isShow={modalDelete.isShow}
        setShow={(e) => setModalDelete({ isShow: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ isShow: !modalDelete.isShow, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => modalDelete.id && clickDelete(modalDelete.id)}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить зону доставки?
      </CustomModal>
    </>
  );
};

export default Zones;
