import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "../../../components/UI/Select";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";

const RedirectForm = ({ data, onSubmit }) => {
  const brand = useSelector((state) => state?.brand?.active);

  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  return (
    <Card body className="mb-4">
      <h5 className="mb-4">Настройки</h5>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="Домен"
              placeholder="Введите домен"
              name="relationModule.options.domain"
              errors={errors}
              defaultValue={form?.relationModule?.options?.domain}
              register={register}
              validation={{ required: "Обязательное поле" }}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="ID Android Market"
              placeholder="Введите id приложения в маркете"
              name="relationModule.options.android"
              errors={errors}
              defaultValue={form?.relationModule?.options?.android}
              register={register}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="ID App Store"
              placeholder="Введите id приложения в маркете"
              name="relationModule.options.ios"
              errors={errors}
              defaultValue={form?.relationModule?.options?.ios}
              register={register}
            />
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </Card>
  );
};
export default RedirectForm;
