import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getAdditions = async (data) => {
  const response = await $authApi.get(apiRoutes.ADDITIONS, {
    params: data,
  });
  return response?.data;
};

const getAddition = async (id) => {
  const response = await $authApi.get(apiRoutes.ADDITION, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createAddition = async (data) => {
  const response = await $authApi.post(apiRoutes.ADDITION_CREATE, data);
  return response?.data;
};

const editAddition = async (data) => {
  const response = await $authApi.post(apiRoutes.ADDITIONS, data);
  return response?.data;
};

const deleteAddition = async (ids) => {
  const response = await $authApi.delete(apiRoutes.ADDITIONS, {
    data: { ids },
  });
  return response?.data;
};

export {
  getAdditions,
  getAddition,
  createAddition,
  editAddition,
  deleteAddition,
};
