import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "../../../components/UI/Select";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";

const AcquiringForm = ({ data, onSubmit }) => {
  const brand = useSelector((state) => state?.brand?.active);

  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  return (
    <Card body className="mb-4">
      <h5 className="mb-4">Настройки</h5>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Select
              label="Тип"
              placeholder="Выберите тип"
              value={form?.relationModule?.options?.type}
              data={[
                {
                  title: "Тинькофф",
                  value: "tinkoff",
                  image: "/images/modules/mini/tinkoff.webp",
                },
                {
                  title: "Сбербанк",
                  value: "sberbank",
                  image: "/images/modules/mini/sber.webp",
                },
                {
                  title: "Альфа Банк",
                  value: "alfa",
                  image: "/images/modules/mini/alfa.webp",
                },
                {
                  title: "ВТБ",
                  value: "vtb",
                  image: "/images/modules/mini/vtb.webp",
                },
                {
                  title: "ЮКасса",
                  value: "yookassa",
                  image: "/images/modules/mini/yookassa.png",
                },
              ]}
              onClick={(e) => setValue("relationModule.options.type", e.value)}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="Логин\Токен"
              placeholder="Введите логин или токен"
              name="relationModule.options.login"
              errors={errors}
              defaultValue={form?.relationModule?.options?.login}
              register={register}
              validation={{ required: "Обязательное поле" }}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              type="password"
              label="Пароль"
              name="relationModule.options.password"
              placeholder="Необязательно"
              errors={errors}
              defaultValue={form?.relationModule?.options?.password}
              register={register}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="Идентификатор"
              placeholder="Введите идентификатор (Необязательно)"
              name="relationModule.options.id"
              errors={errors}
              defaultValue={form?.relationModule?.options?.id}
              register={register}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="Ссылка на webhook"
              readOnly={false}
              defaultValue={`${process.env.REACT_APP_BASE_URL}/webhook/pay/${
                brand?.id ?? ""
              }/`}
            />
            <p className="text-muted fs-07 mt-1">
              Данную ссылку нужно указать в настройках вашего банка, куда будут
              приходить уведомления о проведенных платежах.
            </p>
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </Card>
  );
};
export default AcquiringForm;
